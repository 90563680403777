import { useState } from 'react';
import RedactionModal from './RedactionModal';

const useRedactionModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const showRedactionModal = () => {
    setIsVisible(true);
  };

  const hideRedactionModal = () => {
    setIsVisible(false);
  };

  return {
    RedactionModal,
    isVisible,
    showRedactionModal,
    hideRedactionModal,
  };
};

export default useRedactionModal;
