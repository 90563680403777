import { v4 as uuid } from 'uuid';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate/rotate.inline.svg';
import {
  IPoint,
  IShape,
  RedactionAction,
  RedactionState,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import { convertSvgToDataUrl } from '@/Framework/dataHelpers/formatters/convertSvgToDataUrl';

class TextTool implements IShape<RedactionType.Text> {
  constructor() {
    this.iconImage.src = convertSvgToDataUrl(IconRotate, '#fff');
  }

  public id: string = uuid();

  public categoryId: string = '';

  public categoryName: string = '';

  public redactionId: string = uuid();

  public type = RedactionType.Text;

  public ctx = {};

  private deleteButtonArea: { x: number, y: number, pageIndex: number };

  private iconImage: HTMLImageElement = new Image();

  public requiredControlPoints = 1;

  public isActive: boolean = false;

  public isToolActive: boolean = true;

  public isHovered: boolean = false;

  public activeColor: string = '#ffea0233';

  public defaultColor = '#00000044';

  public deleteColor: string = '#ffeded80';

  public strokeColor: string = '#000';

  public strokeWidth: number = 0.5;

  public fillColor: string = this.defaultColor;

  public controlPoints: IPoint[] = [];

  public selectedText: string;

  public deviation: number = 4;

  public diameterCloseButton: number = 5;

  public meta: any;

  public label: string = '';

  public action: RedactionAction = RedactionAction.None;

  public state: RedactionState = RedactionState.Pending;

  public setState = (state: RedactionState) => {
    this.state = state;
  };

  public setCategoryId = (id: string) => {
    this.categoryId = id;
  };

  public setCategoryName = (name: string) => {
    this.categoryName = name;
  };

  public setRedactionId = (redactionId: string) => {
    this.redactionId = redactionId;
  };

  public setAction = (action: RedactionAction) => {
    this.action = action;
  };

  private getDeleteButtonArea = () => {
    const minPageIndex = Math.min(...this.controlPoints.map((point) => point.pageIndex));
    return (
      this.controlPoints
        .filter((point) => point.pageIndex === minPageIndex)
        .reduce((deleteButtonArea, point) => {
          // try to find the max x1 point of the min y point
          if (Math.min(point.y, point.y1) <= deleteButtonArea.y) {
            deleteButtonArea.x = point.x1;
            deleteButtonArea.y = point.y;
          }
          return deleteButtonArea;
        }, {
          x: -Infinity,
          y: Infinity,
          pageIndex: minPageIndex,
        })
    );
  };

  public setId = (id: string) => {
    this.id = id;
  };

  public bindContext = (canvas: HTMLCanvasElement, pageIndex: number) => {
    this.ctx[pageIndex] = canvas.getContext('2d');
  };

  public addControlPoint = (controlPoint: IPoint) => {
    this.controlPoints.push(controlPoint);
  };

  public setMetadata = (meta: any) => {
    this.meta = meta;
  };

  public draw = () => {
    this.controlPoints.forEach(({
      x,
      y,
      x1,
      y1,
      pageIndex,
    }) => {
      const ctx = this.ctx[pageIndex];
      if (!ctx) return;

      const width = x1 - x;
      const height = y1 - y;

      ctx.fillStyle = this.fillColor;
      ctx.strokeStyle = this.strokeColor;

      ctx.beginPath();
      ctx.rect(x, y, width, height);
      ctx.fill();
    });

    const isSavedAction = (
      this.action !== RedactionAction.None &&
      this.isHovered
    );

    if (isSavedAction) {
      this.action === RedactionAction.Delete
        ? this.drawRestoreButton()
        : this.drawDeleteButton();
    }
  };

  public drawDeleteButton = () => {
    this.deleteButtonArea = this.getDeleteButtonArea();

    const ctx = this.ctx[this.deleteButtonArea.pageIndex];
    if (!ctx) return;

    ctx.beginPath();
    ctx.arc(this.deleteButtonArea.x, this.deleteButtonArea.y, this.diameterCloseButton, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#cc4d4d';
    ctx.fill();

    ctx.font = '10px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#fff';
    ctx.textBaseline = 'middle';
    ctx.fillText('x', this.deleteButtonArea.x, this.deleteButtonArea.y);
  };

  public drawRestoreButton = () => {
    this.deleteButtonArea = this.getDeleteButtonArea();

    const ctx = this.ctx[this.deleteButtonArea.pageIndex];
    if (!ctx) return;

    const drawIcon = () => {
      ctx.beginPath();
      ctx.arc(
        this.deleteButtonArea.x,
        this.deleteButtonArea.y,
        this.diameterCloseButton,
        0, 2 * Math.PI,
        false,
      );
      ctx.fillStyle = '#2d2d2d';
      ctx.strokeStyle = '#2d2d2d';
      ctx.fill();
      ctx.stroke();
      ctx.drawImage(this.iconImage, this.deleteButtonArea.x - 3.5, this.deleteButtonArea.y - 3.5, 7, 7);
    };

    if (this.iconImage.complete) {
      drawIcon();
      return;
    }

    this.iconImage.onload = () => drawIcon();
  };

  public setFillColor = (color: string) => {
    this.fillColor = color;
  };

  public setIsHovered = (value: boolean) => {
    this.isHovered = value;
  };

  public isShapeArea = (x: number, y: number, pageIndex: number) => (
    this.action !== RedactionAction.None &&
    this.controlPoints
      .filter((point) => point.pageIndex === pageIndex)
      .some((point) => (
        x > Math.min(point.x, point.x1) - this.deviation && x < Math.max(point.x, point.x1) + this.deviation &&
        y > Math.min(point.y, point.y1) - this.deviation && y < Math.max(point.y, point.y1) + this.deviation
      ))
  );

  public isDeleteButtonArea = (x: number, y: number, pageIndex: number) => {
    if (!this.isHovered) return false;

    return (
      pageIndex === this.deleteButtonArea?.pageIndex &&
      x > this.deleteButtonArea.x - this.diameterCloseButton &&
      x < this.deleteButtonArea.x + this.diameterCloseButton &&
      y > this.deleteButtonArea.y - this.diameterCloseButton &&
      y < this.deleteButtonArea.y + this.diameterCloseButton
    );
  };

  public setIsActive = (value: boolean) => {
    this.isActive = value;
  };

  public setToolActive = (value: boolean) => {
    this.isToolActive = value;
  };

  public setLabel = (label: string) => {
    this.label = label;
  };

  public serialize = () => ({
    type: this.type,
    id: this.id,
    label: this.label,
    action: this.action,
    redactionId: this.redactionId,
    state: this.state,
    categoryId: this.categoryId,
    categoryName: this.categoryName,
  });
}

export default TextTool;
