import { getRowRects } from './getRowRects';
import { getControlPoint } from './getControlPoint';
import {
  IRowRect,
  IShape,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

interface IPointProps {
  rects: IRowRect[],
  shape: IShape,
  startPageRect: DOMRect,
  scale: number,
  pageIndex: number,
  selectedText: string[],
}

export const addControlPoints = ({
  rects,
  shape,
  startPageRect,
  scale,
  pageIndex,
  selectedText,
}: IPointProps) => {
  getRowRects(rects)
    .forEach((rect) => {
      shape.addControlPoint(getControlPoint(rect, startPageRect, scale, pageIndex));
      selectedText.push(rect.selectedText);
    });
};
