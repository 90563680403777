import { PanelGroup, Panel, PanelArrow } from '@dealroadshow/uikit/core/components/PanelGroup';
import IconTriangleArrowBottom from '@dealroadshow/uikit/core/components/Icon/IconTriangleArrowBottom';
import IconTriangleArrowTop from '@dealroadshow/uikit/core/components/Icon/IconTriangleArrowTop';
import {
  ICategoryCollection,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/types';
import RedactionsZeroCase
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/CategoriesList/RedactionsZeroCase';
import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import {
  RedactionAction,
  RedactionStructureType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';
import CategoryItem
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/CategoriesList/CategoryItem';

import styles from './categoriesList.scss';

interface IProps {
  collection: ICategoryCollection[],
  redactionTab: RedactionTab,
  deleteShape: (id: string, action: RedactionAction, structureType: RedactionStructureType) => void,
  jumpToArea: (id: string) => void,
  zeroIcon: IconComponentType,
}

const arrowUp = <PanelArrow icon={ IconTriangleArrowBottom } arrowWrapperClassName={ styles.arrowWrapper } />;
const toPascalCase = (str: string) => upperFirst(camelCase(str));

const arrowDown = (
  <PanelArrow
    iconActive={ IconTriangleArrowTop }
    isActive
    arrowWrapperClassName={ styles.arrowWrapper }
  />
);

const CategoriesList = ({
  collection = [],
  deleteShape,
  redactionTab,
  jumpToArea,
  zeroIcon,
}: IProps) => {
  if (!collection.length) {
    return (
      <RedactionsZeroCase
        icon={ zeroIcon }
        redactionTab={ redactionTab }
      />
    );
  }

  const renderCategories = (collections) => (
    collections
      .map(({
          redactions,
          categoryName,
        }) => {
          const count = redactions.length;
          return (
            <Panel
              id={ categoryName }
              key={ categoryName }
              dataTest={ `categoryPanel${ categoryName }` }
              showArrow
              arrow={ arrowUp }
              arrowActive={ arrowDown }
              className={ styles.panelGroupItem }
              headerClass={ styles.panelGroupHeader }
              arrowWrapperClass={ styles.panelGroupArrowIcon }
              contentClass={ styles.content }
              header={
                <>{ categoryName } { count && (count > 99 ? '(99+)' : `(${ count })`) }</>
              }
              destroyOnToggle={ false }
            >
              <ul className={ styles.categoryList } data-test={ `list${ toPascalCase(categoryName) }` }>
                { redactions.map((elem) => (
                  <CategoryItem
                    redactionItemData={ elem }
                    jumpToArea={ jumpToArea }
                    deleteShape={ deleteShape }
                  />
                  ),
                ) }
              </ul>
            </Panel>
          );
        },
      )
  );

  return (
    <PanelGroup
      dataTest={ 'categoryPanelGroup' }
      className={ styles.panelGroup }
    >
      { renderCategories(collection) }
    </PanelGroup>
  );
};

export default CategoriesList;
