import {
  IPoint,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getControlPoint = (
  rect: DOMRect & { selectedText?: string },
  offset: DOMRect,
  scale: number,
  pageIndex: number,
): IPoint => (
  rect?.x && ({
    x: (rect.x - offset.left) / scale,
    y: (rect.y - offset.top) / scale,
    x1: (rect.right - offset.left) / scale,
    y1: (rect.bottom - offset.top) / scale,
    pageIndex,
  })
);
