import {
  IShape,
  RedactionAction,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getFillColor = (shape: IShape) => {
  if (shape.isActive) return shape.activeColor;

  switch (shape.action) {
    case RedactionAction.Delete:
    case RedactionAction.Restore:
      return shape.deleteColor;
    default:
      return shape.defaultColor;
  }
};
