import { Dispatch, SetStateAction } from 'react';
import {
  IPoint,
  IShape,
  RedactionAction,
  RedactionState,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

interface IProps {
  oldControlPoints?: Partial<IPoint>[],
  oldId?: string,
  setShapesState: Dispatch<SetStateAction<IShape[]>>,
  index?: number,
  redactionIds?: string[],
  isUpdate?: boolean,
  shape?: IShape,
  idField?: string,
  id?: string,
  isSearch?: boolean,
}

const actionsMap = {
  [RedactionAction.Add]: {
    onSuccess: ({
      redactionIds,
      setShapesState,
    }: IProps) => {
      setShapesState((shapesState) => shapesState.map((shape) => {
        if (redactionIds.includes(shape.redactionId)) {
          shape.setAction(RedactionAction.Add);
        }

        return shape;
      }));
    },
    onError: ({
      setShapesState,
      isUpdate,
      oldControlPoints,
      oldId,
      index,
      redactionIds,
      shape,
    }: IProps) => {
      setShapesState((shapesState) => {
        if (isUpdate) {
          shape.controlPoints = oldControlPoints;
          shape.setId(oldId);
          shape.setAction(RedactionAction.Add);
          const filteredShapes = shapesState.filter((item) => item.id !== shape.id);
          return [
            ...filteredShapes.slice(0, index),
            shape,
            ...filteredShapes.slice(index),
          ];
        }
        return shapesState.filter(
          (shape) => !redactionIds.some((id) => shape.redactionId.includes(id)),
        );
      });
    },
  },
  [RedactionAction.Delete]: {
    onSuccess: ({
      setShapesState,
      idField,
      id,
    }: IProps) => {
      setShapesState((shapesState) => {
        const shapes = shapesState.filter((shape) => shape[idField] === id);
        const isApplied = shapes.every((shape) => shape.state === RedactionState.Applied);
        const deletedShapes = isApplied ? shapes : [];

        if (isApplied) {
          shapes.forEach((shape) => {
            shape.setAction(RedactionAction.Delete);
            shape.setIsActive(false);
          });
        }

        return [...shapesState.filter((shape) => shape[idField] !== id), ...deletedShapes];
      });
    },
    onError: ({
      setShapesState,
      idField,
      id,
    }: IProps) => {
      setShapesState((shapesState) => shapesState.map((shape) => {
        if (shape[idField] === id) {
          shape.state === RedactionState.Applied
            ? shape.setAction(RedactionAction.Applied)
            : shape.setAction(RedactionAction.Add);
        }

        return shape;
      }));
    },
  },
  [RedactionAction.Restore]: {
    onSuccess: ({
      setShapesState,
      idField,
      id,
    }: IProps) => {
      setShapesState((shapesState) => shapesState.map((shape) => {
        if (shape[idField] === id) shape.setAction(RedactionAction.Applied);

        return shape;
      }));
    },
    onError: ({
      setShapesState,
      idField,
      id,
    }: IProps) => {
      setShapesState((shapesState) => shapesState.map((shape) => {
        if (shape[idField] === id) shape.setAction(RedactionAction.Delete);

        return shape;
      }));
    },
  },
};

export default actionsMap;
