import { TabList, Tab } from '@dealroadshow/uikit/core/components/Tabs';
import cn from 'classnames';
import toArray from '@/Framework/dataHelpers/enum/toArray';
import tabsStyles from '@dealroadshow/uikit/core/styles/tabs.scss';
import styles from './tabs.scss';

export enum RedactionTab {
  Pending = 'Pending',
  Applied = 'Applied',
}

interface IProps {
  activeTab: RedactionTab,
  onTabChange: (tab: RedactionTab) => void,
  byPendingCount: number,
  byAppliedCount: number,
}

const Tabs = ({
  activeTab,
  onTabChange,
  byPendingCount,
  byAppliedCount,
}: IProps) => {
  const tabContent = {
    [RedactionTab.Pending]: {
      title: RedactionTab.Pending,
      totalCount: byPendingCount,
    },
    [RedactionTab.Applied]: {
      title: RedactionTab.Applied,
      totalCount: byAppliedCount,
    },
  };

  return (
    <TabList className={ styles.tabs }>
      { toArray(RedactionTab)
        .map((tab) => {
          const tabName = tab
            .toString()
            .toLowerCase();

          return (
            <Tab
              key={ tabName }
              className={ cn(tabsStyles.tab, styles.tab) }
              activeClassName={ cn(tabsStyles.isTabActive, styles.isTabActive) }
              tabFor={ tab }
              selected={ tab === activeTab }
              onClick={ () => onTabChange(tab as RedactionTab) }
              name={ `${ tabName }Tab` }
              counter={ tabContent[tab].totalCount }
            >
              { tabContent[tab].title }
            </Tab>
          );
        }) }
    </TabList>
  );
};

export default Tabs;
