import { v4 as uuid } from 'uuid';
import {
  IPoint as IPointGeneral,
  RedactionType,
  IShape,
  RedactionAction,
  RedactionState,
} from '../types';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate/rotate.inline.svg';
import { convertSvgToDataUrl } from '@/Framework/dataHelpers/formatters/convertSvgToDataUrl';

interface IPoint extends Partial<IPointGeneral> {
}

export default abstract class AbstractAreaTool implements IShape<RedactionType.Area> {
  constructor(ctx: CanvasRenderingContext2D, pageIndex?: number) {
    this.ctx[pageIndex] = ctx;
    this.iconImage.src = convertSvgToDataUrl(IconRotate, '#fff');
  }

  private iconImage: HTMLImageElement = new Image();

  public ctx: { [pageIndex: number]: CanvasRenderingContext2D } = {};

  public redactionId: string = uuid();

  public id: string = uuid();

  public categoryId: string = '';

  public categoryName: string = '';

  public type: RedactionType;

  public isActive: boolean = false;

  public isToolActive: boolean = true;

  public isDragging: boolean = false;

  public isHovered: boolean = false;

  public activeColor: string = '#ffea0233';

  public defaultColor: string = '#00000044';

  public deleteColor: string = '#ffeded80';

  public strokeColor: string = '#000';

  public label: string = '';

  public strokeWidth: number = 0.5;

  public fillColor: string = this.defaultColor;

  private amountControlPoints: number = 0;

  public requiredControlPoints: number = 2;

  public controlPoints: IPoint[] = [];

  public controlPointSize: number = 2.5;

  public deviation: number = 4;

  public diameterCloseButton: number = 5;

  public meta: any;

  public action: RedactionAction = RedactionAction.None;

  public state: RedactionState = RedactionState.Pending;

  public draw: (nextPoint?: IPoint) => void;

  public isShapeArea: (x: number, y: number, pageIndex: number) => boolean;

  public isDeleteButtonArea: (x: number, y: number) => boolean;

  public getHandleIndex: (x: number, y: number) => number | null;

  public setRedactionId = (redactionId: string) => {
    this.redactionId = redactionId;
  };

  public setState = (state: RedactionState) => {
    this.state = state;
  };

  public setCategoryId = (id: string) => {
    this.categoryId = id;
  };

  public setCategoryName = (name: string) => {
    this.categoryName = name;
  };

  public setAction = (action: RedactionAction) => {
    this.action = action;
  };

  public setId = (id: string) => {
    this.id = id;
  };

  public bindContext = (canvas: HTMLCanvasElement, pageIndex: number) => {
    this.ctx[pageIndex] = canvas.getContext('2d');
  };

  public addControlPoint = (controlPoint: IPoint) => {
    if (this.amountControlPoints < this.requiredControlPoints) {
      this.controlPoints[0] = { ...this.controlPoints[0] ?? {}, ...controlPoint };
      controlPoint.x1 && controlPoint.y1
        ? this.amountControlPoints = this.requiredControlPoints
        : this.setAmountControlPoints();
    }
  };

  public drawControlPoints = () => {
    const {
      x,
      y,
      x1,
      y1,
      pageIndex,
    } = this.controlPoints[0];
    const ctx = this.ctx[pageIndex];
    if (!this.ctx) {
      return;
    }

    ctx.strokeStyle = this.strokeColor;
    ctx.fillStyle = '#fff';

    ctx.beginPath();
    ctx.rect(
      x - this.controlPointSize,
      y - this.controlPointSize,
      2 * this.controlPointSize,
      2 * this.controlPointSize,
    );

    this.amountControlPoints === this.requiredControlPoints && (
      ctx.rect(
        x1 - this.controlPointSize,
        y1 - this.controlPointSize,
        2 * this.controlPointSize,
        2 * this.controlPointSize,
      )
    );

    ctx.fill();
    ctx.stroke();
  };

  public drawDeleteButton = () => {
    const {
      x,
      y,
      x1,
      y1,
      pageIndex,
    } = this.controlPoints[0];

    const ctx = this.ctx[pageIndex];
    if (!ctx) return;

    const pointX = Math.max(x, x1);
    const pointY = Math.min(y, y1);

    ctx.beginPath();
    ctx.arc(pointX, pointY, 5, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#cc4d4d';
    ctx.strokeStyle = '#cc4d4d';
    ctx.fill();
    ctx.stroke();

    ctx.font = '9px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#fff';
    ctx.textBaseline = 'middle';
    ctx.fillText('x', pointX, pointY);
  };

  public drawRestoreButton = () => {
    const {
      x,
      y,
      x1,
      y1,
      pageIndex,
    } = this.controlPoints[0];

    const ctx = this.ctx[pageIndex];
    if (!ctx) return;

    const pointX = Math.max(x, x1);
    const pointY = Math.min(y, y1);

    const drawIcon = () => {
      ctx.beginPath();
      ctx.arc(pointX, pointY, this.diameterCloseButton, 0, 2 * Math.PI, false);
      ctx.fillStyle = '#2d2d2d';
      ctx.strokeStyle = '#2d2d2d';
      ctx.fill();
      ctx.stroke();
      ctx.drawImage(this.iconImage, pointX - 3.5, pointY - 3.5, 7, 7);
    };

    if (this.iconImage.complete) {
      drawIcon();
      return;
    }

    this.iconImage.onload = () => drawIcon();
  };

  public isFinished = () => this.amountControlPoints === this.requiredControlPoints;

  public move = (deltaX: number, deltaY: number) => {
    this.controlPoints = this.controlPoints.map(({
      x,
      x1,
      y,
      y1,
      pageIndex,
    }) => ({
      x: x + deltaX,
      y: y + deltaY,
      x1: x1 + deltaX,
      y1: y1 + deltaY,
      pageIndex,
    }));
  };

  public resize = (controlPointIndex: number, x: number, y: number) => {
    if (controlPointIndex) {
      this.controlPoints[0].x1 = x;
      this.controlPoints[0].y1 = y;
    } else {
      this.controlPoints[0].x = x;
      this.controlPoints[0].y = y;
    }
  };

  private setAmountControlPoints = () => {
    this.amountControlPoints += 1;
  };

  public setMetadata = (meta: any) => {
    this.meta = meta;
  };

  public setIsHovered = (value: boolean) => {
    this.isHovered = value;
  };

  public setFillColor = (color: string) => {
    this.fillColor = color;
  };

  public setIsActive = (value: boolean) => {
    this.isActive = value;
  };

  public setToolActive = (value: boolean) => {
    this.isToolActive = value;
  };

  public setIsDragging = (value: boolean) => {
    this.isDragging = value;
  };

  public setLabel = (label: string) => {
    this.label = label;
  };

  public serialize = () => ({
    type: this.type,
    id: this.id,
    label: this.label,
    action: this.action,
    redactionId: this.redactionId,
    state: this.state,
    categoryId: this.categoryId,
    categoryName: this.categoryName,
  });
}
