import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';
import {
  isPending,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/helpers/isPending';
import {
  isApplied,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/helpers/isApplied';
import {
  IRedactionListItem,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

const tabMap = {
  [RedactionTab.Pending]: isPending,
  [RedactionTab.Applied]: isApplied,
};

export const getRedactionsTab = (redactions: IRedactionListItem[], tab: RedactionTab) => (
  redactions
    .filter(tabMap[tab])
    .reverse()
);
