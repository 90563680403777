import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import { ResponsiveModal as Modal } from '@dealroadshow/uikit/core/components/Modal';
import PreviewNotAvailable from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/PreviewNotAvailable';
import DataroomViewer from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer';
import styles from './documentViewer.scss';

const DocumentViewer = () => {
  const {
    previewItem,
    resetPreview,
    isFetching,
    isError,
  } = useDocumentPreviewContext();

  if (!previewItem && !isFetching && !isError) {
    return null;
  }

  if (previewItem.previewable) {
    return (
      <DataroomViewer />
    );
  }

  return (
    <Modal
      title="Preview Not Available"
      isVisible
      className={ styles.previewNotAvailableModal }
      onCloseClicked={ resetPreview }
      dataTest="previewNotAvailableModal"
    >
      { (footerRefCallback) => (
        <PreviewNotAvailable
          item={ previewItem }
          closeModal={ resetPreview }
          footerRefCallback={ footerRefCallback }
        />
      ) }
    </Modal>
  );
};

export default DocumentViewer;
