import { ReactNode } from 'react';
import cn from 'classnames';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import termsOfUseStyles from './termsOfUse.scss';

interface IProps {
  className?: string,
  children?: ReactNode,
}

const TermsOfUse = ({
  className,
  children = (
    <>
      By continuing, you agree to FINSIGHT's <br />
      <a href={ finsightWebUrl.getTermsOfUseUrl() } target="_blank">
        Terms of Use
      </a>{ ' ' }
      and{ ' ' }
      <a href={ finsightWebUrl.getPrivacyPolicyUrl() } target="_blank">
        Privacy Policy
      </a>
    </>
  ),
}: IProps) => {
  return <div className={ cn(termsOfUseStyles.wrp, className) }>{ children }</div>;
};

export default TermsOfUse;
