import {
  IShape,
  RedactionAction,
  RedactionState,
  RedactionStructureType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getShapesToDelete = (
  id: string,
  shape: IShape,
  structureType = RedactionStructureType.Redaction,
  action = RedactionAction.Delete,
) => {
  const {
    label,
    state,
    categoryId,
  } = shape;

  const shapeState = state === RedactionState.Pending
    ? { state: 'pending' }
    : {};

  return ({
    shapes: [{
      id,
      label,
      categoryId,
      ...shapeState,
    }],
    structureType,
    action,
    categoryId,
  });
};
