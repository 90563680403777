const i18n = {
  locales: ['en', 'ja'],
  defaultLocale: 'en',
  localeDetection: true,
  detection: {
    // order and from where user language should be detected
    order: ['path', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'NEXT_LOCALE',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,

    cookieMinutes: 60 * 24 * 30, // 30 days
    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  },
};

export default i18n;
