import AbstractAreaTool
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/AreaTool/AbstractAreaTool';
import {
  RedactionAction,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

class AreaTool extends AbstractAreaTool {
  public type = RedactionType.Area;

  public draw = (nextPoint?: { x1: number, y1: number }) => {
    if (!nextPoint && !this.isFinished()) {
      return;
    }

    const {
      x,
      y,
      x1: right,
      y1: bottomRight,
      pageIndex,
    } = this.controlPoints[0];
    const ctx = this.ctx[pageIndex];

    if (!ctx) return;

    const isReadyToAction = (
      this.isToolActive &&
      this.isHovered
    );

    const isSavedAction = (
      this.action !== RedactionAction.None &&
      this.isHovered &&
      this.isFinished()
    );

    const x1 = (nextPoint?.x1 || right);
    const y1 = (nextPoint?.y1 || bottomRight);

    ctx.beginPath();
    ctx.strokeStyle = this.strokeColor;
    ctx.fillStyle = this.fillColor;

    ctx.rect(x, y, (x1 - x), (y1 - y));
    ctx.lineWidth = this.strokeWidth;
    ctx.fill();
    isReadyToAction && ctx.stroke();

    if (isSavedAction) {
      isReadyToAction && this.action === RedactionAction.Add && this.drawControlPoints();
      this.action === RedactionAction.Delete
        ? this.drawRestoreButton()
        : this.drawDeleteButton();
    }
  };

  public isShapeArea = (x: number, y: number, pageIndex: number) => {
    const {
      x: pointX,
      y: pointY,
      x1: pointX1,
      y1: pointY1,
      pageIndex: pointPageIndex,
    } = this.controlPoints[0];

    if (pageIndex !== pointPageIndex || this.action === RedactionAction.None) return false;

    return (
      x > Math.min(pointX, pointX1) - this.deviation && x < Math.max(pointX, pointX1) + this.deviation &&
      y > Math.min(pointY, pointY1) - this.deviation && y < Math.max(pointY, pointY1) + this.deviation
    );
  };

  public isDeleteButtonArea = (x: number, y: number) => {
    const {
      x: pointX,
      y: pointY,
      x1: pointX1,
      y1: pointY1,
    } = this.controlPoints[0];

    if (!this.isHovered) return false;

    const x1 = Math.max(pointX, pointX1);
    const y1 = Math.min(pointY, pointY1);

    return (
      x > x1 - this.diameterCloseButton &&
      x < x1 + this.diameterCloseButton &&
      y > y1 - this.diameterCloseButton &&
      y < y1 + this.diameterCloseButton
    );
  };

  public getHandleIndex = (x: number, y: number) => {
    const {
      x: pointX,
      y: pointY,
      x1: pointX1,
      y1: pointY1,
    } = this.controlPoints[0];

    if (pointX - this.deviation < x && x < pointX + this.deviation) {
      if (pointY - this.deviation < y && y < pointY + this.deviation) {
        return 0;
      }
    } else if (pointX1 - this.deviation < x && x < pointX1 + this.deviation) {
      if (pointY1 - this.deviation < y && y < pointY1 + this.deviation) {
        return 1;
      }
    }
    return null;
  };
}

export default AreaTool;
