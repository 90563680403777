import AIButton from '@/dataroom/ui/common/DataroomExplorer/Actions/AIButton';
import {
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import IconText from '@dealroadshow/uikit/core/components/Icon/IconText';
import IconUnion from '@dealroadshow/uikit/core/components/Icon/IconUnion';

interface IProps {
  isModalVisible: boolean,
  handleCloseAIModal: () => void,
  resetRedactions: () => void,
}

export const getRadioButtons = (props: IProps) => {
  return [{
    key: 'redactByTextSelectionButton',
    dataTest: 'redactByTextSelectionButton',
    label: <IconText />,
    value: RedactionType.Text,
  },
    {
      key: 'redactByDrawingButton',
      dataTest: 'redactByDrawingButton',
      label: <IconUnion />,
      value: RedactionType.Area,
    },
    {
      key: 'redactByAIButton',
      dataTest: 'redactByAIButton',
      label: <AIButton { ...props } />,
      value: RedactionType.AI,
    }];
};
