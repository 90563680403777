import URL from './urlConstants.mjs';

const redirectsRoutes = async () => (
  [
    {
      source: URL.OLD_ROUTES.NRSRO_RESEARCH,
      destination: URL.NRSRO_RESEARCH_REPORTS,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.SOLUTIONS,
      destination: URL.SOLUTIONS,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.BOOK_DEMO_ALLOCATE,
      destination: URL.BOOK_DEMO_ALLOCATE,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.POLICIES_COMPLIANCE,
      destination: URL.POLICIES_COMPLIANCE,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.POLICIES_PRIVACY,
      destination: URL.POLICIES_PRIVACY,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.POLICIES_SECURITY,
      destination: URL.POLICIES_SECURITY,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.POLICIES_TERMS,
      destination: URL.POLICIES_TERMS,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.PRODUCT_US_ABS_PIPELINE,
      destination: URL.PRODUCT_US_ABS_PIPELINE,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.PRODUCT_US_ABS_EE,
      destination: URL.PRODUCT_US_ABS_EE,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.MARKET_OVERVIEW_ALL,
      destination: URL.MARKET_OVERVIEW_ALL,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.MARKET_OVERVIEW_CONSUMER,
      destination: URL.MARKET_OVERVIEW_CONSUMER,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.ABS_BONDSCREENER,
      destination: URL.ABS_BONDSCREENER,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.CONTACT,
      destination: URL.CONTACT,
      permanent: true,
    },
    {
      source: URL.OLD_ROUTES.FEATURES,
      destination: URL.FEATURES,
      permanent: true,
    },
    {
      source: URL.NRSRO_RESEARCH,
      destination: `${ URL.NRSRO_RESEARCH }/all`,
      permanent: true,
    },
  ]
)

export default redirectsRoutes;
