import {
  IRectProps,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getRectProps = (
  element: HTMLElement,
  startOffset: number,
  endOffset: number,
): IRectProps => ({
  startElement: element,
  startOffset,
  endOffset,
  selectedText: element.textContent.substring(startOffset, endOffset),
});
