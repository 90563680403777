import {
  RedactionAction,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import IconRotate from '@dealroadshow/uikit/core/components/Icon/IconRotate';
import IconCancelCircle from '@dealroadshow/uikit/core/components/Icon/IconCancelCircle';
import styles from './CategoryItem/categoryItem.scss';

export const getIconProps = (action: RedactionAction) => {
  switch (action) {
    case RedactionAction.Delete:
      return {
        Icon: IconRotate,
        className: styles.restoreButton,
        'data-test': 'redactionRestoreButton',
        action: RedactionAction.Restore,
      };
    default:
      return {
        Icon: IconCancelCircle,
        className: styles.deleteButton,
        'data-test': 'redactionDeleteButton',
      };
  }
};
