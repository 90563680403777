export const RESEARCHROOM_FEATURE = 'researchroomEnabled';
export const REQUESTS_SUMMARY = 'requestsSummary';
export const INVESTOR_TARGETING_FEATURE = 'investorTargeting';

export const featureToggle = {
  [RESEARCHROOM_FEATURE]: {
    cookieName: RESEARCHROOM_FEATURE,
  },
  [REQUESTS_SUMMARY]: {
    cookieName: REQUESTS_SUMMARY,
  },
  [INVESTOR_TARGETING_FEATURE]: {
    cookieName: INVESTOR_TARGETING_FEATURE,
  },
};
