import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import {
  IAICategory,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export default function useManualCategory() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [category, setCategory] = useState<IAICategory>(null);

  const getManualCategory = async (fileId: number) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        fileId,
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      const category = await redactionRepository.getManualCategory(payload);
      setCategory(category);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    category,
    getManualCategory,
  };
}
