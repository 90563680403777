import { domain } from '@/Framework/config';
import env from '@framework/env/env';
import i18n from '@/dealroadshow/application/config/lang/i18n.mjs';
import { IDealroadshowConfig } from '@/dealroadshow/domain/vo/IDealroadshowConfig';

const config: IDealroadshowConfig = {
  name: 'Deal Roadshow',
  code: 'dealroadshow',
  abbr: 'DRS',
  supportEmail: env('CREDENTIALS__DEAL_ROADSHOW__SUPPORT__EMAIL'),
  supportPhoneNumber: env('CREDENTIALS__DEAL_ROADSHOW__SUPPORT__PHONE'),
  hostname: `${ domain.drsSubdomain }.${ domain.globalDomain }`,
  useApiProxy: true,
  ga: {
    trackingId: env('GA__TRACKING_ID_DEALROADSHOW'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_DEALROADSHOW__GTM_ID'),
  },
  options: {
    isNewApp: false,
  },
  isConsentForEDeliveryRequired: false,
  supportChatId: env('FRONT_APP__CHAT_ID_TENANT_DEALROADSHOW'),
  presentation: {
    changeSlide: {
      debounce: 200,
    },
    prefetchSlides: {
      debounce: 150,
      amount: 7,
    },
    idleTime: 8 * 60 * 1000, // 8m in ms
    idleLogoutTime: 30 * 60 * 1000, // 30m in ms
    maxIdleTime: 3 * 60 * 60 * 1000, // 3h in ms
  },
  i18n,
};

export default config;
