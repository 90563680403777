import RedactSidebar from './RedactSidebar';
import { Spinner } from '@dealroadshow/uikit/core/components/Loader/Spinner';
import {
  RedactionPlugin,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/useRedactionPlugin';
import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';
import styles from './redactionMode.scss';

export interface IProps {
  isOpen: boolean,
  handleClose: () => void,
  redactionPlugin: RedactionPlugin,
  isDocumentLoading: boolean,
  isRedactionEnabled: boolean,
}

const RedactionMode = (props: IProps) => {
  const { isFetching } = useDataroomRedactionContext();

  if (!props.isRedactionEnabled) {
    return null;
  }

  return (
    <div className={ styles.redactionMode }>
      <RedactSidebar { ...props } />
      <Spinner
        overlay
        isVisible={ props.isDocumentLoading || isFetching }
        overlayClassName={ styles.redactionSpinner }
      />
    </div>
  );
};

export default RedactionMode;
