import React, { createContext, useContext, useEffect, useRef } from 'react';
import useArea from './AreaTool/useArea';
import { IOptions, IShape, RedactionAction, RedactionType } from './types';
import useText from './TextTool/useText';
import {
  addPageIndexAttribute,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/addPageIndexAttribute';
import {
  getFillColor,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/getFillColor';
import {
  getAction,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/getAction';

export interface IProps {
  redactionType: RedactionType,
  width: number,
  height: number,
  pageIndex: number,
  scale: number,
  shapes: IShape[],
  textLayerRef: HTMLDivElement,
  handleInsertShapes: (shapes: IShape[], options: IOptions) => void,
  handleUpdateShape: (id: string, oldControlPoints: IShape<RedactionType.Area>['controlPoints']) => void,
  isPreviewWithRedaction: boolean,
  deleteShape: (id: string, action: RedactionAction) => void,
  resetSelectedShape: () => void,
  canvasRefState: React.MutableRefObject<HTMLCanvasElement>,
}

const useShapes = ({
  redactionType,
  width,
  height,
  scale,
  pageIndex,
  textLayerRef,
  shapes,
  isPreviewWithRedaction,
  handleInsertShapes,
  handleUpdateShape,
  deleteShape,
  resetSelectedShape,
}: Omit<IProps, 'canvasRefState'>) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const isDrawingToolActive = redactionType === RedactionType.Area && !isPreviewWithRedaction;
  const isTextToolActive = redactionType === RedactionType.Text && !isPreviewWithRedaction;
  const ref = isDrawingToolActive ? canvasRef.current : textLayerRef;

  const setCanvasRef = (ref: HTMLCanvasElement) => {
    const pixelRatio = window.devicePixelRatio || 1;

    canvasRef.current = ref;
    const canvas = canvasRef.current;

    canvas.style.width = `${ width }px`;
    canvas.style.height = `${ height }px`;
    canvas.width = width * pixelRatio;
    canvas.height = height * pixelRatio;
    canvas.getContext('2d')
      .scale(scale * pixelRatio, scale * pixelRatio);
    canvas.getContext('2d')
      .translate(0.5, 0.5);
  };

  const draw = () => {
    cleanCanvases();

    shapes.forEach((shape) => {
      if (isPreviewWithRedaction) {
        shape.setIsActive(false);
        shape.setFillColor(shape.strokeColor);
        shape.setToolActive(false);
        shape.action !== RedactionAction.Delete && shape.draw();
        return;
      }

      shape.setFillColor(getFillColor(shape));
      shape.setToolActive(isDrawingToolActive);
      shape.draw();
    });
  };

  const cleanCanvases = () => {
    const {
      width,
      height,
    } = canvasRef.current.style;
    canvasRef.current.getContext('2d')
      .clearRect(0, 0, parseInt(width) / scale, parseInt(height) / scale);

    shapes.forEach(({
      ctx,
      controlPoints,
    }) => {
      Object.keys(ctx)
        .forEach((pageIndex) => {
          if (controlPoints.some((point) => point.pageIndex === Number(pageIndex))) {
            const {
              width,
              height,
            } = ctx[pageIndex].canvas.style;
            ctx[pageIndex].clearRect(0, 0, parseInt(width) / scale, parseInt(height) / scale);
          }
        });
    });
  };

  const getMouseOffset = (e) => {
    const textLayerRect = textLayerRef.getClientRects();
    return {
      x: e.clientX - textLayerRect[0].x,
      y: e.clientY - textLayerRect[0].y,
    };
  };

  const getHoveredShapes = ({
    x,
    y,
  }: { x: number, y: number }) => {
    return shapes.filter((shape) => shape.isShapeArea(x / scale, y / scale, pageIndex));
  };

  const handleMouseMove = (e: MouseEventInit) => {
    if (isPreviewWithRedaction) return;

    shapes.forEach((shape) => shape.setIsHovered(false));

    const hoveredShapes = getHoveredShapes(getMouseOffset(e));

    if (hoveredShapes.length) {
      hoveredShapes.forEach((shape) => shape.setIsHovered(true));
      ref.style.cursor = 'pointer';
      ref.classList.add('active');
    } else {
      ref.style.cursor = 'default';
      ref.classList.remove('active');
    }

    draw();
  };

  const handleMouseDown = (e: MouseEventInit) => {
    if (isPreviewWithRedaction) return;

    const mouseOffset = getMouseOffset(e);
    const hoveredShapes = getHoveredShapes(mouseOffset);
    const {
      x,
      y,
    } = mouseOffset;

    const hoveredShape = hoveredShapes.find((shape) => (
      shape.isDeleteButtonArea(x / scale, y / scale, pageIndex)
    ));

    if (hoveredShape) {
      deleteShape(hoveredShape.id, getAction(hoveredShape));
    }

    !hoveredShape?.isActive && resetSelectedShape();
    draw();
  };

  const handleMouseOut = () => {
    shapes.forEach((shape) => shape.setIsHovered(false));
    draw();
  };

  useEffect(() => {
    textLayerRef && addPageIndexAttribute(textLayerRef, pageIndex.toString());
  }, [textLayerRef]);

  useEffect(() => {
    canvasRef.current && shapes.forEach((shape) => {
      shape.bindContext(canvasRef.current, pageIndex);
    });
  }, [canvasRef.current, shapes, pageIndex]);

  useEffect(draw, [shapes, textLayerRef, isPreviewWithRedaction, redactionType]);

  useEffect(() => {
    ref && ref.addEventListener('mousemove', handleMouseMove);
    ref && ref.addEventListener('mousedown', handleMouseDown);
    ref && ref.addEventListener('mouseout', handleMouseOut);

    return () => {
      ref && ref.removeEventListener('mousemove', handleMouseMove);
      ref && ref.removeEventListener('mousedown', handleMouseDown);
      ref && ref.removeEventListener('mouseout', handleMouseOut);
    };
  }, [canvasRef, redactionType, handleMouseMove, handleMouseDown, handleMouseOut]);

  const pluginProps = {
    canvasRef,
    scale,
    handleInsertShapes,
    handleUpdateShape,
  };

  useArea({
    isEnabled: isDrawingToolActive,
    shapes: shapes.filter(
      ({ type }) => (type === RedactionType.Area),
    ) as IShape<RedactionType.Area>[],
    pageIndex,
    ...pluginProps,
  });

  useText({
    isEnabled: isTextToolActive,
    textLayerRef,
    ...pluginProps,
  });

  return { setCanvasRef };
};

type DrawingShapesContextType = ReturnType<typeof useShapes>;

export const ShapesContext = createContext<DrawingShapesContextType>(null);

export const useShapesContext = () => {
  const context = useContext(ShapesContext);
  if (!context) {
    throw new Error('useShapesContext must be used within a ShapesContextProvider');
  }
  return context;
};

const ShapesContextProvider = ({
  children,
  ...props
}: IProps & {
  children: React.ReactNode,
}) => (
  <ShapesContext.Provider value={ useShapes(props) }>
    { children }
  </ShapesContext.Provider>
);

export default ShapesContextProvider;
