import {
  RedactionAction,
  RedactionState,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getState = (action: RedactionAction) => {
  switch (action) {
    case RedactionAction.Applied:
    case RedactionAction.Delete:
      return RedactionState.Applied;
    default:
      return RedactionState.Pending;
  }
};
