import URL from './urlConstants.mjs';

const articleProductTypes = 'all|abs|clo|close|cmbs|general|m-and-a|midday|open|retail|rmbs|ssa|tenders';

const rewriteRoutes = async () => (
  [
    {
      source: '/robots.txt',
      destination: '/api/robots.txt'
    },
    {
      source: '/:routeType(all-abs-market|high-yield-corporate|investment-grade-corporate)-bond-issuance-overview',
      destination: '/market-all/:routeType',
    },
    {
      source: `${ URL.ISSUER }-:companyViewId(\\d+)-:issuerName?`,
      destination: '/issuer/:companyViewId/:issuerName?',
    },
    {
      source: `/sponsor-:companyViewId(\\d+)-:sponsorName?`,
      destination: '/sponsor/:companyViewId/:sponsorName?',
    },
    {
      source: `/deal-:companyViewId(\\d+)-:issuerName?`,
      destination: `/deal/:companyViewId/:issuerName?`,
    },
    {
      source: `/:sector-${ URL.SECTOR_SUFFIX }`,
      destination: `/${ URL.SECTOR_SUFFIX }/:sector`,
    },
    {
      source: `/:industry-${ URL.INDUSTRY_SUFFIX }`,
      destination: `/${ URL.INDUSTRY_SUFFIX }/:industry`,
    },
    {
      source: '/:bondType(inventories|bwics)-:assetClass',
      destination: "/bwics-inventories/:bondType/:assetClass",
    },
    {
      source: '/trace-:assetClass(abs|clo|cmbs|rmbs|othr)',
      destination: '/trace/:assetClass',
    },
    {
      source: '/dealer-summary-:bondType(inventories|bwics)-:assetClass?',
      destination: "/bwics-inventories/dealer-summary/:bondType/:assetClass?",
    },
    {
      source: '/:leagueType(underwriter|rating-agency)-:viewId-:companyName?',
      destination: "/league-table/:leagueType/:viewId/:companyName?",
    },
    {
      source: '/:routeType(deal|sponsor|issuer)/:viewId(\\d+)',
      destination: "/redirect/:routeType/:viewId"
    },
    {
      source: '/profile-:viewId(\\d+)-:profileName',
      destination: '/openmarket/profile/:viewId/:profileName',
    },
    {
      source: `/:articleType(commentary-deal-flow|commentary|deal-flow)-:productType(${ articleProductTypes })`,
      destination: '/creditFlow/:articleType/:productType',
    },
    {
      source: '/post-:articleId',
      destination: '/creditFlow/article/:articleId',
    },
    {
      source: '/new-issue-stats-:categoryId',
      destination: '/creditFlow/stats/:categoryId',
    },
    {
      source: '/stat-:statId',
      destination: '/creditFlow/stat/:statId',
    }
  ]
)

export default rewriteRoutes;
