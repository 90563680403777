import { createContext, useContext, ReactNode } from 'react';
import useAICategories from '@/dataroom/application/redaction/useAICategories';
import { useCheckFiles } from '@/dataroom/application/redaction/useCheckFiles';
import useScanning from '@/dataroom/application/redaction/useScanning';

const useScanRedaction = () => {
  return {
    scanning: useScanning(),
    aICategories: useAICategories(),
    checkFiles: useCheckFiles(),
  };
};

export const ScanRedactionContext = createContext<ReturnType<typeof useScanRedaction>>(null);

export const useScanRedactionContext = () => {
  const context = useContext(ScanRedactionContext);
  if (!context) {
    throw new Error('useScanRedactionContext must be used within a ScanRedactionContextProvider');
  }
  return context;
};

interface IProps {
  children: ReactNode,
}

const ScanRedactionContextProvider = ({ children }: IProps) => (
  <ScanRedactionContext.Provider value={ useScanRedaction() }>
    { children }
  </ScanRedactionContext.Provider>
);

export default ScanRedactionContextProvider;
