import { IconComponentType } from '@dealroadshow/uikit/core/components/Icon';
import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';
import styles from './redactionsZeroCase.scss';

interface IProps {
  icon: IconComponentType,
  redactionTab: RedactionTab,
}

const RedactionsZeroCase = ({
  icon: Icon,
  redactionTab,
}: IProps) => (
  <div className={ styles.zeroContainer }>
    <Icon className={ styles.icon } />
    <p className={ styles.zeroText }>{ `${ redactionTab } redactions will display here.` }</p>
  </div>
);

export default RedactionsZeroCase;
