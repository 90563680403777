import React, { useEffect, useCallback, useRef } from 'react';
import { Modal } from '@dealroadshow/uikit/core/components/Modal';
import { Button, ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import FilesList from '@/dataroom/ui/common/DataroomExplorer/Modals/RedactionModal/FilesList';
import { useScanRedactionContext } from '@/dataroom/application/redaction/ScanRedactionContext';
import {
  filterSelectedFiles,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/RedactionModal/helpers/filterSelectedFiles';
import FinalForm from '@/Framework/UI/Organisms/FinalForm';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconRedaction from '@dealroadshow/uikit/core/components/Icon/IconRedaction';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import isEqual from 'lodash/isEqual';
import CategoriesCheckboxGroup
  from '@/dataroom/ui/common/DataroomExplorer/Modals/RedactionModal/CategoriesCheckboxGroup';
import { validateForm } from '@/dataroom/ui/common/DataroomExplorer/Modals/RedactionModal/helpers/validateForm';

import styles from './redactionModal.scss';

interface IInitialValues {
  selectedCategories: string[],
  keywordInput: string,
}

interface IProps {
  selected?: IFilesystemListItem[],
  isVisible: boolean,
  onClose: () => void,
  isSingleFileRedaction?: boolean,
  title: string,
  handleScan: (formData: IInitialValues) => void,
}

const bulkDescription = (
  <>
    <b>Note:</b> Only PDF files (excluding scanned, password-protected, and PDFs over 100MB) will be added to the
    Redaction Area.
  </>
);

const singleDescription = `You can redact using keywords or choose from a selection of predefined redaction categories
powered by AI. Please note, AI redaction does not guarantee 100% accuracy, and it is important to review documents
before finalizing any redactions. Additionally, if AI does not identify any matches in selected documents during
scanning, they will not appear in the redaction area.`;

const initialValues: IInitialValues = {
  selectedCategories: [],
  keywordInput: '',
};

const RedactionModal = ({
  selected = [],
  isVisible,
  onClose,
  isSingleFileRedaction = false,
  title,
  handleScan,
}: IProps) => {
  const isFormDirty = useRef(false);
  const {
    scanning: {
      isFetching: scanForReviewIsFetching,
    },
    aICategories: {
      isFetching: isFetchingCategories,
      aICategories,
      getRedactionAICategories,
    },
    checkFiles: {
      setCanRedact,
    },
  } = useScanRedactionContext();

  useEffect(() => {
    isVisible && isSingleFileRedaction && getRedactionAICategories();
  }, [isVisible, isSingleFileRedaction]);

  const handleOnClose = () => {
    onClose();
    setCanRedact(false);
  };

  const renderFormContent = useCallback(
    ({
      error,
      invalid,
    }, {
      Field,
      FormSpy,
    }) => {
      return (
        <>
          <FormSpy
            onChange={ ({
              dirty,
              values,
              initialValues,
            }) => {
              isFormDirty.current = dirty && !isEqual(initialValues, values);
            } }
          />
          { !isSingleFileRedaction && (
            <FilesList collection={ filterSelectedFiles(selected) } />
          ) }
          <p className={ styles.note }>
            { isSingleFileRedaction ? singleDescription : bulkDescription }
          </p>
          { !isSingleFileRedaction && (
            <Field
              type="text"
              placeholder="Type a keyword to redact"
              name="keywordInput"
              dataTest="redactByKeywordInput"
              icon={ IconRedaction }
              isClearable
              component={ Input }
              isNarrow
              className={ styles.keywordInput }
            />
          ) }
          { isSingleFileRedaction && (
            <CategoriesCheckboxGroup
              isFetching={ isFetchingCategories }
              categories={ aICategories }
              field={ Field }
            />
          ) }
          <div className={ styles.footer }>
            <Button
              type="submit"
              variant={ ButtonVariantType.action }
              title="Scan for Review"
              disabled={ !isFormDirty.current || invalid || !!error }
              dataTest="scanForReviewButton"
            />
            <Button
              variant={ ButtonVariantType.text }
              title="Cancel"
              onClick={ handleOnClose }
              dataTest="cancelButton"
            />
          </div>
        </>
      );
    },
    [isFetchingCategories],
  );

  if (!isVisible) return null;

  return (
    <>
      <Modal
        isVisible={ isVisible }
        className={ styles.redactModal }
        onCloseClicked={ handleOnClose }
        title={ title }
        dataTest="redactionModal"
      >
        <>
          <FinalForm
            name="redactionModalForm"
            dataTest="redactionModalForm"
            validate={ !isSingleFileRedaction && validateForm }
            onSubmit={ handleScan }
            initialValues={ initialValues }
            render={ renderFormContent }
          />
          <Spinner
            isVisible={ scanForReviewIsFetching }
            overlay
          />
        </>
      </Modal>
    </>
  );
};

export default RedactionModal;
