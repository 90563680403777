import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import {
  IAICategory,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export default function useAICategories() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [aICategories, setAICategories] = useState<IAICategory[]>([]);

  const getRedactionAICategories = async () => {
    setIsFetching(true);

    try {
      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);
      const { categories } = await redactionRepository.getAICategoriesList({
        dataroomId: dataroom.id,
      });
      setAICategories(categories);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
    } finally {
      setIsFetching(false);
    }
  };
  return {
    isFetching,
    aICategories,
    getRedactionAICategories,
  };
}
