import { validateMaxLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMaxLength';
import { validateMinLength } from '@/Framework/UI/Organisms/FinalForm/validators/validateMinLength';
import {
  groupValidators,
} from '@/Framework/UI/Organisms/FinalForm/validators/helpers';

const MIN_LENGTH = 3;
const MAX_LENGTH = 128;

const fieldParams = {
  fieldName: 'keywordInput',
  fieldCaption: 'Search',
};

export const validateForm = ({ keywordInput = '' }) => groupValidators(
  validateMinLength({
    ...fieldParams,
    value: keywordInput || ' ',
    length: MIN_LENGTH,
  }),
  validateMaxLength({
    ...fieldParams,
    value: keywordInput,
    length: MAX_LENGTH,
  }),
);
