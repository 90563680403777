import {
  IRedactionListItem,
  RedactionAction,
  RedactionState,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const isPending = ({
  state,
  action,
}: IRedactionListItem) => (
  state === RedactionState.Pending ||
  action === RedactionAction.Delete ||
  action === RedactionAction.None ||
  action === RedactionAction.Restore
);
