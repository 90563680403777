import {
  IShape,
  RedactionAction,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getAction = (shape: IShape) => {
  switch (shape.action) {
    case RedactionAction.Delete:
      return RedactionAction.Restore;
    default:
      return RedactionAction.Delete;
  }
};
