import { ReactNode } from 'react';
import { Plugin } from '@react-pdf-viewer/core';

export enum ViewerPlugin {
  Redaction = 'redaction',
}

export interface IRedactionPlugin {
  isUserSelectEnabled: boolean,
  isRedactionModeVisible: boolean,
  redactButtonComponent: (onClick: () => void, className: string) => ReactNode | null,
  redactionModeComponent: ReactNode,
  redactionPlugin: Plugin,
}
