import {
  IRowRect,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getRowRects = (rects: IRowRect[]) => {
  const rowRects = [];
  let rect = null;
  // workaround if we have gaps
  const threshold = 5;
  const maxGap = 15;

  rects.forEach(({
    rect: domRect,
    selectedText,
  }) => {
    if (domRect.width === 0 || domRect.height === 0) return;

    const {
      x,
      y,
      right,
      bottom,
    } = domRect;

    if (!rect || Math.abs(y - rect.y) > threshold || x - rect.right > maxGap) {
      rect = {
        x,
        y,
        right,
        bottom,
        selectedText,
      };
      rowRects.push(rect);
      return;
    }

    rect.x = Math.min(rect.x, x);
    rect.right = Math.max(rect.right, right);
    rect.bottom = Math.max(rect.bottom, bottom);
    rect.selectedText += selectedText;
  });

  return rowRects;
};
