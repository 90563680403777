import { useState } from 'react';

import ConfirmationModal from './ConfirmationModal';

export default function useConfirmationModal() {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  function showConfirmationModal() {
    setIsVisible(true);
  }

  function hideConfirmationModal() {
    setIsVisible(false);
  }

  return {
    ConfirmationModal,
    isVisible,
    showConfirmationModal,
    hideConfirmationModal,
  };
}
