import { IShape, RedactionType } from './types';
import AreaTool from './AreaTool/tools/AreaTool';
import TextTool from './TextTool/tools/TextTool';
import SearchTool from './SearchTool/tools/SearchTool';
import AITool
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/AITool/AITool';

class RedactionToolFactory {
  static create = (type: RedactionType, ctx?: CanvasRenderingContext2D, pageIndex?: number): IShape => {
    switch (type) {
      case RedactionType.Text:
        return new TextTool();
      case RedactionType.Search:
        return new SearchTool();
      case RedactionType.AI:
        return new AITool();
      case RedactionType.Area:
      default:
        return new AreaTool(ctx, pageIndex);
    }
  };
}

export default RedactionToolFactory;
