import React from 'react';
import { Tooltip } from '@dealroadshow/uikit/core/components/Tooltip';
import IconQuestion from '@dealroadshow/uikit/core/components/Icon/IconQuestion';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import { CheckboxInput, CheckboxInputProps } from '@dealroadshow/uikit/core/components/Checkbox';
import camelCase from 'lodash/camelCase';

import styles from './categoriesCheckboxGroup.scss';

interface Category {
  id: string,
  name: string,
  description?: string,
}

interface Props {
  categories: Category[],
  isFetching: boolean,
  field: React.FC<CheckboxInputProps>,
}

// Will delete when AI model fixed
const betaCategories = ['Addresses', 'Company names', 'Full names'];
const labelTooltipText = 'This criteria is in beta and may not yet provide fully accurate results.';

const CategoriesCheckboxGroup: React.FC<Props> = ({
  categories,
  isFetching,
  field: Field,
}) => {
  const listContent = (
    <div className={ styles.checkboxList }>
      { categories.map(({
        id,
        name,
        description = '',
      }) => (
        <div key={ id } className={ styles.checkboxItem }>
          <Field
            type="checkbox"
            id={ id }
            dataTest={ camelCase(name) }
            name="selectedCategories"
            value={ id }
            label={ name }
            component={ CheckboxInput }
          />
          { betaCategories.includes(name) && (
            <Tooltip containerClassName={ styles.tooltipContainer } content={ labelTooltipText } maxWidth={ 235 }>
              <span className={ styles.betaLabel }>beta</span>
            </Tooltip>
          ) }
          { description && (
            <Tooltip containerClassName={ styles.tooltipContainer } content={ description }>
              <IconQuestion className={ styles.icon } />
            </Tooltip>
          ) }
        </div>
      )) }
    </div>
  );

  return (
    <div className={ styles.groupWrapper }>
      { isFetching ? <Spinner /> : listContent }
    </div>
  );
};

export default CategoriesCheckboxGroup;
