import React, { Suspense, useEffect, useState } from 'react';
import cn from 'classnames';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
import PortalWrp, { PortalId } from '@/Framework/UI/Templates/PortalWrp';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import { useDataroomTenantContext } from '@/dataroom/application/DataroomTenantContext';
import { LazyDocumentViewer } from '@/Framework/UI/Organisms/DocumentViewer';
import { canDownload, canRedactFile } from '@/dataroom/domain/filesystemPermissions';
import { canUserBulkDownload } from '@/dataroom/domain/managePermissions';
import { useCurrentUserContext } from '@/dataroom/application/CurrentUserContext';
import styles from '../documentViewer.scss';
import { useDownloadContext } from '@/dataroom/application/DownloadContext';
import DataroomRedactionContext, {
  useDataroomRedactionContext,
} from '@/dataroom/application/redaction/DataroomRedactionContext';
import { useRedactionPermissions } from '@/dataroom/application/redaction/useRedactionPermissions';
import {
  useRedactionPlugin,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/useRedactionPlugin';
import {
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import { ViewerPlugin } from '@/Framework/UI/Organisms/DocumentViewer/plugins/types';
import Button from '@dealroadshow/uikit/core/components/Button';
import IconRedaction from '@dealroadshow/uikit/core/components/Icon/IconRedaction';
import useIsMobile from '@/Framework/hooks/useIsMobile';
import { isScreenS } from '@dealroadshow/uikit/core/styles/screen/screen';
import RedactionMode
  from 'src/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode';
import { PreviewMode } from '@/dataroom/domain/vo/PreviewModeType';

const DataroomViewer = () => {
  const { tenant } = useDataroomTenantContext();
  const { dataroom } = useDataroomContext();
  const { currentUser } = useCurrentUserContext();
  const isMobile = useIsMobile(isScreenS);

  const {
    requestDownload,
    download,
  } = useDownloadContext();

  const {
    previewItem,
    defaultSearch,
    previewMode,
    resetPreview,
    fileWatermark,
    isFetching,
    isError,
    isRedactionModeEnabled,
    setPreviewMode,
    onCloseCallback,
  } = useDocumentPreviewContext();

  const {
    listing: {
      resetRedactions,
    },
    search: {
      resetSearchData,
    },
  } = useDataroomRedactionContext();

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isRedactionModeVisible, setIsRedactionModeVisible] = useState(false);
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false);

  const userCanBulkDownload = canUserBulkDownload(currentUser);
  const { userCanRedactFile } = useRedactionPermissions([previewItem]);
  const canRedact = canRedactFile(currentUser, previewItem, 1);

  const {
    dataroom: {
      watermarking,
      stagingWatermarking,
      watermarkingOpacity,
      stagingWatermarkingOpacity,
    },
  } = useDataroomContext();

  const showWatermark = previewItem.isStaging ? stagingWatermarking : watermarking;
  const opacity = previewItem.isStaging ? stagingWatermarkingOpacity : watermarkingOpacity;
  const isRedactionEnabled = isRedactionModeEnabled && userCanRedactFile;
  const isRedacting = isRedactionEnabled && isRedactionModeVisible;
  const isRedactionPreviewMode = previewMode === PreviewMode.Redaction;

  const redactionPlugin = useRedactionPlugin(isRedacting, setIsDocumentLoaded);

  const handleFinish = () => {
    setIsDownloadLoading(false);
  };

  const handleDownload = () => {
    setIsDownloadLoading(true);

    requestDownload({
      items: [previewItem],
      onFinish: handleFinish,
    })
      .then(download);
  };

  useEffect(() => {
    if (isRedactionPreviewMode && isDocumentLoaded) {
      setIsRedactionModeVisible(isRedactionPreviewMode);
    }
  }, [isRedactionPreviewMode, isDocumentLoaded]);

  const documentUrl = isRedactionEnabled && (isRedactionModeVisible || isRedactionPreviewMode)
    ? dataroomUrl(tenant).getOriginalPreviewUrl(dataroom.name, previewItem.id)
    : dataroomUrl(tenant).getPreviewUrl(dataroom.name, previewItem.id);

  const handleCloseRedaction = () => {
    setIsRedactionModeVisible(false);
    setIsDocumentLoaded(false);
    setPreviewMode(PreviewMode.None);
    if (isRedactionEnabled) {
      resetRedactions();
      resetSearchData();
      redactionPlugin.setRedactionType(RedactionType.Text);
      redactionPlugin.resetRedactions();
    }
  };

  const handleClose = () => {
    resetPreview();
    handleCloseRedaction();
    onCloseCallback && onCloseCallback();
  };

  const handleRedactButton = (onClick: () => void) => {
    onClick();
    if (!isRedactionModeVisible) {
      setIsRedactionModeVisible(true);
      setIsDocumentLoaded(false);
    }
  };

  const redactButtonComponent = (onClick: () => void, className: string) => (
    canRedact && (
      <>
        <div className={ styles.separator } />
        <Button
          className={ className }
          onClick={ () => handleRedactButton(onClick) }
          disabled={ isMobile }
          dataTest="documentViewerMenuRedactButton"
        >
          <IconRedaction />
          <span>{ isMobile ? 'Redaction (Disabled)' : 'Redact' }</span>
        </Button>
      </>
    )
  );

  const redactionModeComponent = (
    <RedactionMode
      isOpen={ isRedactionModeVisible }
      isRedactionEnabled={ isRedactionEnabled }
      isDocumentLoading={ !isDocumentLoaded && isRedactionModeVisible }
      handleClose={ handleCloseRedaction }
      redactionPlugin={ redactionPlugin }
    />
  );

  return (
    <PortalWrp portalId={ PortalId.PORTAL_OVERLAY_ID }>
      <Suspense>
        <LazyDocumentViewer
          documentTitle={ previewItem?.name }
          defaultSearch={ defaultSearch }
          documentUrl={ documentUrl }
          watermarkText={ showWatermark ? [fileWatermark, 'CONFIDENTIAL INFORMATION - DO NOT DISTRIBUTE'] : [] }
          className={ cn(styles.documentPreviewer,
            { [styles.withRedactionMode]: isRedacting },
            { [styles.textToolActive]: redactionPlugin.redactionType === RedactionType.Text },
          ) }
          close={ handleClose }
          onDownload={ handleDownload }
          isDownloadDisabled={ !canDownload(previewItem, userCanBulkDownload, false, 1) }
          watermarkOpacity={ opacity }
          watermarkWidth={ 480 }
          isFetching={ isFetching }
          isDownloadLoading={ isDownloadLoading }
          isCustomError={ isError }
          defaultScale={ SpecialZoomLevel.ActualSize }
          minZoomLevel={ 50 }
          isBlurProtectionEnabled={ dataroom.blurEnabled }
          blurNotification="As a protection measure, your file is hidden when you are not interacting with the viewer. To view this file, move your mouse pointer back to this page."
          plugins={ {
            [ViewerPlugin.Redaction]: {
              redactionPlugin,
              isRedactionModeVisible,
              isUserSelectEnabled: isRedacting && !redactionPlugin.isPreviewWithRedaction,
              redactButtonComponent,
              redactionModeComponent,
            },
          } }
        />
      </Suspense>
    </PortalWrp>
  );
};

export default (props) => (
  <DataroomRedactionContext>
    <DataroomViewer { ...props } />
  </DataroomRedactionContext>
);
