import {
  IShape,
  RedactionAction,
  RedactionStructureType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const getShapesToAdd = (shapes: IShape[]) => ({
  shapes: shapes.map(({
    serialize,
    controlPoints,
    categoryId,
  }) => ({
    ...serialize(),
    action: RedactionAction.Add,
    controlPoints,
    categoryId,
  })),
  structureType: RedactionStructureType.Redaction,
  action: RedactionAction.Add,
  categoryId: shapes[0]?.categoryId,
});
