import { Dependencies } from 'constitute';
import JsonRpcDispatcher, { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import Request from '@/Framework/api/Rpc/Request';
import UsersRpcDispatcher from '@/users/application/DI/Rpc/HttpDispatcher';
import { ICompany, ICompanyListPayload } from '@/users/domain/vo/Company';

@Dependencies(UsersRpcDispatcher)
class CompaniesRepository {
  private readonly usersRpc: JsonRpcDispatcher;

  constructor(usersRpc) {
    this.usersRpc = usersRpc;
  }

  list = async (
    payload: ICompanyListPayload = {},
  ): Promise<{
    collection: ICompany[],
    totalCount: number,
  }> => {
    const request = new Request('company.list', payload);
    const response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult() as any;
  };

  getById = async (id) => {
    const req = new Request('company.get_by_id', { id });
    const response = await this.usersRpc.call<RpcSuccess>(req);

    return response.getResult();
  };

  create = async (data: ICompany): Promise<ICompany> => {
    const request = new Request('company.create', data);
    const response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  update = async (payload: ICompany): Promise<ICompany> => {
    const request = new Request('company.update', payload);
    const response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * @param {Number} id
   * @return {Object}
   */
  deleteById = async (id) => {
    const request = new Request('company.delete', { id });
    const response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * @param {Object} payload
   * @return {Object}
   */
  bulkDelete = async (payload) => {
    const request = new Request('company.bulk_delete', payload);
    const response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };

  /**
   * @param {Object} payload
   * @return {Object}
   */
  restore = async (payload) => {
    let request = new Request('company.restore', payload);
    let response = await this.usersRpc.call<RpcSuccess>(request);

    return response.getResult();
  };
}

export default CompaniesRepository;
