export const getRectFromOffsets = (textDiv: HTMLElement, startOffset: number, endOffset: number) => {
  const clonedElement = textDiv.cloneNode(true);

  const { firstChild } = clonedElement;
  const range = new Range();

  // this try/catch is necessary because the range.setStart and range.setEnd methods can throw an error
  try {
    range.setStart(firstChild, startOffset);
    range.setEnd(firstChild, endOffset);
  } catch (error) {
    return { width: 0 } as DOMRect;
  }
  const wrapper = document.createElement('span');

  textDiv.parentNode.appendChild(clonedElement);
  textDiv.classList.forEach((className) => wrapper.classList.add(className));
  wrapper.style.fontSize = textDiv.style.fontSize;
  wrapper.style.fontFamily = textDiv.style.fontFamily;
  range.surroundContents(wrapper);

  const rect = wrapper.getBoundingClientRect();

  // Remove the clone element
  clonedElement.parentNode.removeChild(clonedElement);

  return rect;
};
