import {
  getPageIndex,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/TextTool/helpers/getPageIndex';

interface IEndElement {
  endElement: HTMLElement,
  lastEndOffset?: number,
}

export const getEndElement = (element: any, textLayers: Element[]): IEndElement => {
  // workaround for the bug when the range.endContainer is HTMLElement not a text
  const endElement = element instanceof HTMLElement
    ? element
    : element.parentNode as HTMLElement;

  // workaround for the bug if endElement is some div inside page container, and
  // we need to get textContent.length for the endOffset
  if (!textLayers.includes(endElement.parentNode as Element)) {
    const pageIndex = getPageIndex(endElement.parentNode as HTMLElement);
    const endTextLayer = textLayers.find((el) => getPageIndex(el) === pageIndex) as HTMLElement;
    const lastElementLayer = endTextLayer?.lastChild as HTMLElement;

    return {
      endElement: lastElementLayer,
      lastEndOffset: lastElementLayer?.textContent.length,
    };
  }

  return { endElement };
};
