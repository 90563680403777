import React, { useState, useEffect } from 'react';
import Input from '@dealroadshow/uikit/core/components/Input';
import IconSearch from '@dealroadshow/uikit/core/components/Icon/IconSearch';
import {
  validateSearchValue,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/helpers/validateSearchValue';
import styles from './search.scss';

interface IProps {
  handleSearchValue: (value: string) => Promise<number>,
  isResetSearchInput: boolean,
}

const ENTER_KEY_CODE = 13;

const Search = ({
  handleSearchValue,
  isResetSearchInput,
}: IProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [validationErrorText, setValidationErrorText] = useState<string | null>(null);
  const [isNotFoundVisible, setIsNotFoundVisible] = useState(false);

  useEffect(() => {
    setValidationErrorText(null);
    setIsNotFoundVisible(false);
    setSearchValue('');
  }, [isResetSearchInput]);

  const onChangeValue = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setValidationErrorText(null);
    setIsNotFoundVisible(false);
    setSearchValue(value);
  };

  const onKeyDown = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === ENTER_KEY_CODE) {
      const validationError = validateSearchValue(searchValue?.trim().length);
      setValidationErrorText(validationError);

      if (!validationError) {
        const resultCount = await handleSearchValue(searchValue.trim());
        setIsNotFoundVisible(resultCount === 0);
      }
    }
  };

  return (
    <div className={ styles.searchWrapper }>
      <Input
        inputClassName={ styles.inputSearch }
        placeholder="Search keyword to redact"
        type="text"
        value={ searchValue }
        name="search"
        dataTest="redactionSearchInput"
        onChange={ onChangeValue }
        // @ts-ignore
        input={ {
          onKeyDown,
        } }
        icon={ IconSearch }
        iconClassName={ styles.searchIcon }
        isNarrow
      />
      { (isNotFoundVisible || validationErrorText) && (
        <p className={ styles.errorText } data-test="validationErrorText">
          { isNotFoundVisible ? 'No results found' : validationErrorText }
        </p>
      ) }
    </div>
  );
};

export default Search;
