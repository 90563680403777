import { domain } from '@framework/config';
import env from '@framework/env/env';
import { featureToggle as finsightFeatureToggle } from '@finsight/config/featureToggle';
import { IFinsightConfig } from '@finsight/config/IFinsightConfig';

const config: IFinsightConfig = {
  name: 'Creditflowresearch',
  code: 'creditflowresearch',
  supportEmail: env('FINSIGHT_WEB___SUPPORT__EMAIL'),
  supportPhoneNumber: env('FINSIGHT_WEB___SUPPORT__PHONE'),
  hostname: `${ domain.creditflowresearchDomain }`,
  useApiProxy: true,
  ga: {
    trackingId: env('GA__TRACKING_ID_FINSIGHT'),
  },
  gtm: {
    trackingId: env('FRONTEND___GOOGLE_TAG_MANAGER__TENANT_FINSIGHT__GTM_ID'),
  },
  supportChatId: env('FRONT_APP__CHAT_ID_TENANT_FINSIGHT'),
  featureToggle: finsightFeatureToggle,
  newissueEmail: `newissue@${ domain.globalDomain }`,
  api: {
    external: `${ domain.protocol }//${ domain.globalDomain }`,
    internal: {
      finsightWeb: `http://${ env('FINSIGHT_WEB_WS__HOSTNAME_INTERNAL') }`,
      finsightStrats: `http://${ env('FINSIGHT_STRATS_WS__HOSTNAME_INTERNAL') }`,
    },
  },
};

export default config;
