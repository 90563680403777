import {
  IPoint,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const isValidControlPoint = (controlPoint: IPoint | Partial<IPoint>) => (
  controlPoint &&
  controlPoint.x &&
  controlPoint.y &&
  controlPoint.x1 !== controlPoint.x &&
  controlPoint.y1 !== controlPoint.y
);
