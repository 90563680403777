import { useState } from 'react';
import { useDIContext } from '@/Framework/DI/DIContext';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { AlertManager } from '@dealroadshow/uikit/core/components/Alert';
import { messageCodes } from '@/Framework/Message/messages';
import { getMessage } from '@/Framework/Message/Mapper/getMessage';
import { getItemsPayload, Items } from '@/dataroom/application/redaction/helpers/getItemsPayload';

export default function useScanning() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState(false);

  const scanForReview = async (search: string, items: Items) => {
    setIsFetching(true);

    try {
      const payload = {
        dataroomId: dataroom.id,
        search,
        items: getItemsPayload(items),
      };

      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      // TODO: need to remove this message after socket implementation
      AlertManager.success(getMessage(messageCodes.DATAROOM_REDACTION_SCAN_BY_KEYWORD_STARTED));
      await redactionRepository.scanByKeyword(payload);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  const scanByCategories = async (fileId: number, categories: string[]) => (
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async (resolve, reject) => {
      setIsFetching(true);

      const onFinish = () => {
        setIsFetching(false);
        resolve(true);
      };

      const onError = () => {
        setIsFetching(false);
        reject();
        AlertManager.error(getMessage(messageCodes.GENERAL_ERROR));
      };

      try {
        const payload = {
          dataroomId: dataroom.id,
          fileId,
          categories,
          onFinish,
          onError,
        };

        const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

        await redactionRepository.scanByCategories(payload);
      } catch (error) {
        setIsFetching(false);
        reject();
        container.get(DataroomErrorHandler)
          .handleError(error);
      }
    }));

  return {
    isFetching,
    scanForReview,
    scanByCategories,
  };
}
