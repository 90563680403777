import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import {
  createStore,
  Destination,
  Plugin,
  PluginFunctions,
  PluginOnDocumentLoad,
  PluginRenderPageLayer,
} from '@react-pdf-viewer/core';
import RedactionLayout from './RedactionLayout';
import {
  IOptions,
  ISerializedShape,
  IShape,
  RedactionAction,
  RedactionStructureType,
  RedactionType,
} from './types';
import { convertRedactionsToShapes } from './helpers/convertRedactionsToShapes';
import actionsMap
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/helpers/actionsMap';
import { isValidControlPoint } from './helpers/isValidControlPoint';
import { getShapesToAdd } from './helpers/getShapesToAdd';
import { getShapesToDelete } from './helpers/getShapesToDelete';
import { useShapesToAction } from './hooks/useShapesToAction';
import {
  findMissingIndex,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/AreaTool/helpers/findMissingIndex';
import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';

export interface IStoreProps {
  jumpToDestination?(destination: Destination): void,
}

type SetStateType = Dispatch<SetStateAction<boolean>>;

export interface RedactionPlugin extends Plugin {
  shapesState: ISerializedShape[],
  jumpToArea: (id: string) => void,
  redactionType: RedactionType,
  setRedactionType: (redactionType: RedactionType) => void,
  isPreviewWithRedaction: boolean,
  setPreviewWithRedaction: SetStateType,
  resetRedactions: () => void,
  deleteShape: (id: string, action: RedactionAction, structureType: RedactionStructureType) => void,
  resetSelectedShape: () => void,
}

type IControlPoints = IShape<RedactionType.Area>['controlPoints'];

export const useRedactionPlugin = (isVisible: boolean, setIsDocumentLoaded: SetStateType): RedactionPlugin => {
  const store = useMemo(() => createStore<IStoreProps>({}), []);

  const [isPreviewWithRedaction, setPreviewWithRedaction] = useState(false);
  const [redactionType, setRedactionType] = useState<RedactionType>(RedactionType.Text);
  const [shapesState, setShapesState] = useState<IShape[]>([]);
  const activeShape = useRef<IShape>(null);
  const canvasRefState = useRef<HTMLCanvasElement>(null);

  const {
    setShapesToAction,
    clearShapesToAction,
    getShapesToAction,
  } = useShapesToAction();

  const { previewItem } = useDocumentPreviewContext();

  const {
    listing: {
      redactions,
    },
    search: {
      redactions: searchRedactions,
    },
    save: {
      saveAction,
    },
  } = useDataroomRedactionContext();

  useEffect(() => {
    if (redactions.length && canvasRefState.current) {
      handleInsertShapes(
        convertRedactionsToShapes(redactions, canvasRefState.current.getContext('2d')),
        { isInitialized: true },
      );
    }
  }, [redactions]);

  useEffect(() => {
    if (searchRedactions?.length && canvasRefState.current) {
      handleInsertShapes(
        convertRedactionsToShapes(searchRedactions, canvasRefState.current.getContext('2d')),
        { isSearch: true },
      );
    }
  }, [searchRedactions]);

  useEffect(() => {
    const shapesToAction = getShapesToAction();

    if (shapesToAction) {
      const {
        shapes,
        action,
        props,
      } = shapesToAction;
      const [shapesToAdd] = shapes;

      const actionToSave = actionsMap[action];

      saveAction(previewItem.id, shapes)
        .then(() => {
          actionToSave.onSuccess(props);
          props.isSearch && jumpToArea(shapesToAdd.shapes[0].id);
        })
        .catch(() => actionToSave.onError(props))
        .finally(clearShapesToAction);
    }
  }, [shapesState]);

  const getRenderingPageIndexes = (pageIndex: number) => {
    const checkedPages = new Set<number>();
    const pagesQueue = [pageIndex];

    while (pagesQueue.length) {
      const currentPage = pagesQueue.pop();
      if (!checkedPages.has(currentPage)) checkedPages.add(currentPage);

      shapesState.forEach((shape) => {
        if (shape.controlPoints.some((point) => point.pageIndex === currentPage || shape.isActive)) {
          shape.controlPoints.forEach(({ pageIndex }) => {
            if (!checkedPages.has(pageIndex)) pagesQueue.push(pageIndex);
          });
        }
      });
    }

    return Array.from(checkedPages);
  };

  const renderPageLayer = (props: PluginRenderPageLayer): ReactElement => {
    if (!isVisible) return null;

    const pageIndexes = getRenderingPageIndexes(props.pageIndex);
    const filteredShapes = shapesState.filter((shape) => (
      shape.controlPoints.some(({ pageIndex }) => pageIndexes.includes(pageIndex))
    ));

    return (
      <RedactionLayout
        redactionType={ redactionType }
        width={ props.width }
        height={ props.height }
        pageIndex={ props.pageIndex }
        scale={ props.scale }
        handleInsertShapes={ handleInsertShapes }
        handleUpdateShape={ handleUpdateShape }
        shapes={ filteredShapes }
        textLayerRef={ props.textLayerRef.current }
        isPreviewWithRedaction={ isPreviewWithRedaction }
        deleteShape={ deleteShape }
        resetSelectedShape={ resetSelectedShape }
        canvasRefState={ canvasRefState }
      />
    );
  };

  const resetSelectedShape = () => {
    if (activeShape.current) {
      activeShape.current.setIsActive(false);
      activeShape.current = null;
    }
  };

  const jumpToArea = (id: string) => {
    const selectedShape = shapesState.find((shape) => shape.id === id);
    if (!selectedShape) return;

    const {
      pageIndex,
      x,
      y,
    } = selectedShape.controlPoints[0];

    const jumpToDestination = store.get('jumpToDestination');

    if (jumpToDestination) {
      activeShape.current && activeShape.current.setIsActive(false);
      activeShape.current = selectedShape;
      selectedShape.setIsActive(true);

      jumpToDestination({
        bottomOffset: (_: number, viewportHeight: number) => viewportHeight - y,
        leftOffset: (viewportWidth: number) => viewportWidth - x,
        pageIndex,
      });
    }
  };

  const handleInsertShapes = (shapes: IShape[], options: IOptions = {}) => {
    const {
      isAreaPending = false,
      isInitialized = false,
      isSearch = false,
    } = options;

    setShapesState((prevState) => {
      if (isAreaPending) {
        const { missingIndex } = findMissingIndex(prevState);

        shapes[0].setLabel(`Block ${ missingIndex }`);
      }

      return [...prevState, ...shapes];
    });

    if (!isInitialized) {
      setShapesToAction([getShapesToAdd(shapes)], RedactionAction.Add, {
        isSearch,
        setShapesState,
        redactionIds: shapes.map((shape) => shape.redactionId),
      });
    }
  };

  const handleUpdateShape = (redactionId: string, oldControlPoints: IControlPoints) => {
    const index = shapesState.findIndex((shape) => shape.redactionId === redactionId);

    if (index === -1) return;

    const updatedShape = shapesState[index];
    updatedShape.setAction(RedactionAction.None);

    const {
      missingIndex,
      indexes,
    } = findMissingIndex(shapesState);

    missingIndex < indexes.length && updatedShape.setLabel(`Block ${ missingIndex }`);

    const props = {
      oldControlPoints,
      oldId: redactionId,
      setShapesState,
      index,
      isUpdate: true,
      shape: updatedShape,
      redactionIds: [redactionId],
    };

    if (!isValidControlPoint(updatedShape.controlPoints[0])) {
      actionsMap[RedactionAction.Add].onError(props);
      return;
    }

    setShapesState((shapesState) => [
      ...shapesState.filter((shape) => shape.redactionId !== redactionId),
      updatedShape,
    ]);

    const shapesToDelete = getShapesToDelete(redactionId, updatedShape);
    updatedShape.setRedactionId(uuid());
    const shapesToAdd = getShapesToAdd([updatedShape]);

    setShapesToAction([shapesToAdd, shapesToDelete], RedactionAction.Add, {
      ...props,
      redactionIds: [updatedShape.redactionId],
    });
  };

  const resetRedactions = () => {
    setShapesState([]);
  };

  const deleteShape = (
    id: string,
    action = RedactionAction.Delete,
    structureType = RedactionStructureType.Entry,
  ) => {
    const idField = structureType === RedactionStructureType.Entry ? 'id' : 'redactionId';

    const shapes = shapesState.filter((shape) => shape[idField] === id);

    if (!shapes.length) return;

    setShapesState((shapesState) => (
      shapesState.map((shape) => {
        if (shape[idField] === id) {
          action === RedactionAction.Restore
            ? shape.setAction(RedactionAction.Restore)
            : shape.setAction(RedactionAction.None);
        }
        return shape;
      })
    ));

    setShapesToAction([getShapesToDelete(id, shapes[0], structureType, action)], action, {
      setShapesState,
      idField,
      id,
    });
  };

  const onDocumentLoad = (props: PluginOnDocumentLoad) => props.doc && setIsDocumentLoaded(true);

  return {
    install: (pluginFunctions: PluginFunctions) => {
      store.update('jumpToDestination', pluginFunctions.jumpToDestination);
    },
    renderPageLayer,
    shapesState: shapesState.map((shape) => shape.serialize()),
    deleteShape,
    jumpToArea,
    redactionType,
    setRedactionType,
    onDocumentLoad,
    isPreviewWithRedaction,
    setPreviewWithRedaction,
    resetRedactions,
    resetSelectedShape,
  };
};
