import {
  IShape,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import TextTool
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/TextTool/tools/TextTool';

class SearchTool extends TextTool implements IShape<RedactionType.Search> {
  public type = RedactionType.Search;
}

export default SearchTool;
