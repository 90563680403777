import { useEffect, useRef, useState } from 'react';
import { areaMap } from './helpers/areaMap';
import { IPropsRedactionTool, IShape, RedactionType } from '../types';
import { setCommonProps } from './helpers/setCommonProps';
import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';

type IDrawingShape = IShape<RedactionType.Area>;

interface IProps extends IPropsRedactionTool {
  shapes: IDrawingShape[],
  pageIndex: number,
}

const useArea = ({
  isEnabled,
  canvasRef,
  scale,
  handleInsertShapes,
  handleUpdateShape,
  shapes,
  pageIndex,
}: IProps) => {
  const [activeHandleIndex, setActiveHandleIndex] = useState<number>(null);
  const activeShape = useRef<IDrawingShape>(null);
  const oldControlPoints = useRef<IDrawingShape['controlPoints']>([]);

  const {
    manualCategory: {
      category,
    },
  } = useDataroomRedactionContext();

  const setActiveShape = (shape: IDrawingShape | null) => {
    activeShape.current = shape;
  };

  const props = {
    activeShape,
    setActiveShape,
    oldControlPoints,
    pageIndex,
    handleInsertShapes,
    setActiveHandleIndex,
    activeHandleIndex,
    handleUpdateShape,
    canvasRef,
    manualCategory: category,
  };

  const getCommonProps = setCommonProps(props, scale);

  const handleMouseDown = (e) => {
    const commonProps = getCommonProps(e);

    if (!activeShape.current && areaMap.noActiveShape({
      ...commonProps,
      existingShape: shapes.find((shape) => shape.isShapeArea(commonProps.x, commonProps.y, pageIndex)),
    })) {
      return;
    }

    if (activeShape.current.isFinished()) {
      areaMap.finishedShape(commonProps);
    }
  };

  const handleMouseMove = (e) => {
    const commonProps = getCommonProps(e);

    if (areaMap.actionOnShape(commonProps)) return;

    areaMap.drawNewShape(commonProps);

    // Fix for the side effect of text selection
    document.getSelection()
      ?.empty();
  };

  const handleMouseUp = (e) => {
    const commonProps = getCommonProps(e);

    if (areaMap.notFinishedShape(commonProps)) return;

    areaMap.finishAction(props);
    setActiveHandleIndex(null);
  };

  const handleMouseOut = () => {
    activeShape.current && activeShape.current.setIsActive(false);
    setActiveShape(null);
  };

  useEffect(() => {
    if (!isEnabled) return;

    const canvas = canvasRef.current;

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);
    canvas.addEventListener('mouseout', handleMouseOut);

    // eslint-disable-next-line consistent-return
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
      canvas.removeEventListener('mouseout', handleMouseOut);
    };
  }, [canvasRef, activeHandleIndex, isEnabled, shapes, category]);
};

export default useArea;
