import { getRectFromOffsets } from './getRectFromOffsets';
import { getRectsAndTextBetweenElements } from './getRectsBetweenElements';
import {
  IRectProps,
  IRowRect,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

interface IProps {
  start?: IRectProps,
  between?: {
    startElement: HTMLElement,
    endElement: HTMLElement,
  },
  end?: IRectProps,
}

const createRects = (element?: IRectProps): IRowRect[] => (
  element ? [{
    rect: getRectFromOffsets(element.startElement, element.startOffset, element.endOffset),
    selectedText: element.selectedText,
  }] : []);

const createRectsBetween = (element: IProps['between']): IRowRect[] => (
  element
    ? getRectsAndTextBetweenElements(element.startElement, element.endElement)
    : []
);

export const getRects = ({
  start,
  between,
  end,
}: IProps): { rects: IRowRect[] } => ({
  rects: [
    ...createRects(start),
    ...createRectsBetween(between),
    ...createRects(end),
  ],
});
