import {
  IShape,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import TextTool
  from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/TextTool/tools/TextTool';

class AITool extends TextTool implements IShape<RedactionType.AI> {
  public type = RedactionType.AI;
}

export default AITool;
