import FilesystemIcon from '@/dataroom/ui/common/FilesystemIcon';
import { IFilesystemListItem } from '@/dataroom/domain/vo/collection/FilesystemListItem';
import styles from './filesList.scss';

interface IProps {
  collection: IFilesystemListItem[],
}

const AMOUNT_FILES = 5;

const FilesList = ({ collection }: IProps) => {
  const displayedItems = collection.slice(0, AMOUNT_FILES);

  return (
    <div className={ styles.wrapper }>
      <ul className={ styles.filesList }>
        { displayedItems.map(({
          id,
          type,
          extension,
          name,
        }) => (
          <li key={ id } className={ styles.fileItem }>
            <FilesystemIcon type={ type } extension={ extension } className={ styles.icon } />
            <span>{ name }</span>
          </li>
        )) }
      </ul>
      { collection.length > AMOUNT_FILES && (
        <div className={ styles.count }>+{ collection.length - AMOUNT_FILES } more</div>
      ) }
    </div>
  );
};

export default FilesList;
