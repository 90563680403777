import React from 'react';
import Modal from '@dealroadshow/uikit/core/components/Modal';
import Button, { ButtonVariantType } from '@dealroadshow/uikit/core/components/Button';
import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';

import styles from './confirmationModal.scss';

interface IProps {
  activeTab: RedactionTab,
  confirm: (activeTab: RedactionTab) => void,
  onClose: () => void,
  isVisible: boolean,
}

const ConfirmationModal = ({
  activeTab,
  confirm,
  onClose,
  isVisible,
}: IProps) => {
  const confirmHandler = () => {
    onClose();
    confirm(activeTab);
  };

  if (!isVisible) {
    return null;
  }

  const footer = (
    <>
      <Button
        variant={ ButtonVariantType.action }
        className={ styles.removeButton }
        onClick={ confirmHandler }
        title="Remove Redactions"
        dataTest="removeButton"
      />
      <Button
        variant={ ButtonVariantType.text }
        onClick={ onClose }
        title="Cancel"
        dataTest="cancelButton"
      />
    </>
  );

  return (
    <Modal
      className={ styles.confirmationModal }
      onCloseClicked={ onClose }
      isVisible={ isVisible }
      title={ `Remove All ${ activeTab } Redactions` }
      closeOnEsc={ false }
      footer={ footer }
      dataTest="removeAllRedactionsModal"
    >
      <span>
        { `Are you sure you want to remove all ${ activeTab.toLowerCase() } redactions from the selected file? This action cannot be undone.` }
      </span>
    </Modal>
  );
};

export default ConfirmationModal;
