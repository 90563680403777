import { useEffect, useRef } from 'react';
import RedactionToolFactory from '../RedactionToolFactory';
import { RedactionType, IPropsRedactionTool, IShape } from '../types';
import { getPageIndex } from './helpers/getPageIndex';
import { getEndElement } from './helpers/getEndElement';
import { selectionMap } from './helpers/selectionMap';
import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';

interface IProps extends IPropsRedactionTool {
  textLayerRef: HTMLDivElement,
}

const useText = ({
  isEnabled,
  canvasRef,
  scale,
  textLayerRef,
  handleInsertShapes,
}: IProps) => {
  const textLayers = useRef<Element[]>([]);

  const {
    manualCategory: {
      category,
    },
  } = useDataroomRedactionContext();

  const handleMouseUp = () => {
    const selection = document.getSelection();

    if (!selection || selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    const {
      startOffset,
      endOffset: lastOffset,
      collapsed,
      endContainer,
      startContainer,
    } = range;

    // if user made click without text selection
    if (collapsed) return;

    const startElement = startContainer.parentNode as HTMLElement;
    const {
      endElement,
      lastEndOffset,
    } = getEndElement(endContainer, textLayers.current);

    const endOffset = lastEndOffset ?? lastOffset;

    if (!startElement || !endElement) return;

    const startTextLayer = startElement.parentNode as HTMLElement;
    const endTextLayer = endElement.parentNode as HTMLElement;

    const startPageIndex = getPageIndex(startTextLayer);
    const endPageIndex = getPageIndex(endTextLayer);
    const startPageRect = startElement.parentElement.getBoundingClientRect();

    const isSameDiv = startElement === endElement;
    const isDifferentDivs = !isSameDiv && startTextLayer === endTextLayer;
    const isDifferentPages = startTextLayer !== endTextLayer;

    const shape = RedactionToolFactory.create(RedactionType.Text) as IShape<RedactionType.Text>;
    shape.setCategoryId(category?.id);
    shape.setCategoryName(category?.name);
    const selectedText: string[] = [];

    const props = {
      startElement,
      endElement,
      startOffset,
      endOffset,
      textLayers: textLayers.current,
      startPageIndex,
      endPageIndex,
      pointsProps: {
        shape,
        scale,
        startPageRect,
        selectedText,
        pageIndex: startPageIndex,
      },
    };

    if (isSameDiv) selectionMap.addControlPointsFromSamDiv(props);
    if (isDifferentDivs) selectionMap.addControlPointsFromDifferentDivs(props);
    if (isDifferentPages) selectionMap.addControlPointsFromDifferentPages(props);

    shape.setLabel(selectedText.join(' '));
    handleInsertShapes([...(shape.controlPoints.length ? [shape] : [])]);
    document?.getSelection()
      ?.empty();
    textLayers.current = [];
  };

  useEffect(() => {
    textLayers.current = Array.from(document.querySelectorAll('.rpv-core__text-layer'));
  }, [textLayerRef, textLayers.current]);

  useEffect(() => {
    if (!isEnabled) return;

    textLayerRef && textLayerRef.addEventListener('mouseup', handleMouseUp);

    // eslint-disable-next-line consistent-return
    return () => {
      textLayerRef && textLayerRef.removeEventListener('mouseup', handleMouseUp);
    };
  }, [textLayerRef, canvasRef.current, scale, isEnabled, category]);
};

export default useText;
