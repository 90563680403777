import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import {
  IAICategory,
  IRedactionListItem,
  ISerializedShape,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import { getRedactionsTab } from '../../../RedactionMode/RedactSidebar/helpers/getRedactionsTab';
import {
  ICategoryCollection,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/types';
import {
  RedactionTab,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/Tabs/Tabs';

const CATEGORY_ID_KEY: keyof IRedactionListItem = 'categoryId';
const CATEGORY_NAME_KEY: keyof IRedactionListItem = 'categoryName';

export const groupRedactions = (shapesState: IRedactionListItem[]) => (
  Object.values(groupBy(shapesState, 'redactionId'))
    .map((group) => ({
      ...group[0],
      count: group.length,
    }))
);

const groupCategories = (collection: IRedactionListItem[]) => (
  Object.entries(groupBy(collection, CATEGORY_ID_KEY))
    .map(([categoryId, redactions]) => ({
      categoryId,
      [CATEGORY_NAME_KEY]: redactions[0]?.[CATEGORY_NAME_KEY] ?? '',
      redactions,
      count: redactions.length,
    }))
);

export const groupByCategory = (
  redactions: IRedactionListItem[],
  type: RedactionTab,
  category: IAICategory,
) => {
  const collection = getRedactionsTab(redactions, type);

  const sortByDynamicManualCategory = (item: ISerializedShape) => (item[CATEGORY_ID_KEY] === category?.id ? 0 : 1);

  return orderBy(
    groupCategories(collection)
      .map((item) => ({
        ...item,
        [CATEGORY_NAME_KEY]: item?.[CATEGORY_NAME_KEY],
      })),
    [sortByDynamicManualCategory, CATEGORY_NAME_KEY],
  ) as ICategoryCollection[];
};
