import {
  IShape,
  RedactionType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';

export const findMissingIndex = (shapes: IShape[]) => {
  const indexes = shapes
    .filter((shape) => shape.type === RedactionType.Area)
    .map((shape) => Number(shape.label?.split(' ')[1]))
    .sort((a, b) => a - b);

  const missingIndex = indexes.findIndex((number, i) => number !== i + 1) + 1;

  return {
    missingIndex: missingIndex || indexes.length + 1,
    indexes,
  };
};
