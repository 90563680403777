import { useDataroomRedactionContext } from '@/dataroom/application/redaction/DataroomRedactionContext';
import { useDocumentPreviewContext } from '@/dataroom/application/DocumentPreviewContext';
import IconAI from '@dealroadshow/uikit/core/components/Icon/IconAI';
import useRedactionModal from '@/dataroom/ui/common/DataroomExplorer/Modals/RedactionModal/useRedactionModal';

interface IProps {
  isModalVisible: boolean,
  handleCloseAIModal: () => void,
  resetRedactions: () => void,
}

const modalTitle = 'Redact by PII Category';

const AIButton = ({
  isModalVisible,
  handleCloseAIModal,
  resetRedactions,
}: IProps) => {
  const {
    listing: {
      getRedactions,
    },
    scan: {
      scanByCategories,
    },
  } = useDataroomRedactionContext();

  const {
    previewItem: {
      id,
    },
  } = useDocumentPreviewContext();

  const {
    RedactionModal,
  } = useRedactionModal();

  const handleScan = ({ selectedCategories }) => {
    handleCloseAIModal();
    scanByCategories(id, selectedCategories)
      .then(resetRedactions)
      .then(() => {
        getRedactions(id);
      });
  };

  return (
    <>
      <IconAI />
      <RedactionModal
        title={ modalTitle }
        onClose={ handleCloseAIModal }
        isVisible={ isModalVisible }
        isSingleFileRedaction
        handleScan={ handleScan }
      />
    </>
  );
};

export default AIButton;
