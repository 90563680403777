import { useEffect, useRef } from 'react';
import ShapesContext, { useShapesContext, IProps } from './ShapesContext';
import styles from './redactionPlugin.scss';

const RedactionLayout = ({ canvasRefState }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { setCanvasRef } = useShapesContext();

  useEffect(() => {
    setCanvasRef(canvasRef.current);
    canvasRefState.current = canvasRef.current;
  }, [canvasRef.current]);

  return (
    <canvas
      className={ styles.drawingCanvas }
      ref={ canvasRef }
    />
  );
};

export default (props: IProps) => (
  <ShapesContext { ...props }>
    <RedactionLayout canvasRefState={ props.canvasRefState } />
  </ShapesContext>
);
