interface IRectWithText {
  rect: DOMRect,
  selectedText: string,
}

export const getRectsAndTextBetweenElements = (startElement: HTMLElement, endElement: HTMLElement) => {
  const rects: IRectWithText[] = [];
  let currentElement = startElement as HTMLElement;

  while (currentElement && (currentElement !== endElement)) {
    const rect = currentElement.getBoundingClientRect();
    const selectedText = currentElement.textContent || ' ';
    rects.push({ rect, selectedText });
    currentElement = currentElement.nextSibling as HTMLElement;
  }

  return rects;
};
