import { addControlPoints } from './addControlPoints';
import { getRects } from './getRects';
import { getPagesBetween } from './getPagesBeteween';
import { getPageIndex } from './getPageIndex';
import {
  IPointProps,
  IRectProps,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import {
  getRectProps,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/TextTool/helpers/getRectProps';

interface IMap extends Omit<IRectProps, 'selectedText'> {
  startElement: HTMLElement,
  endElement: HTMLElement,
  startOffset: number,
  endOffset: number,
  pointsProps: IPointProps,
  textLayers: Element[],
  startPageIndex: number,
  endPageIndex: number,
}

export const selectionMap = {
  addControlPointsFromSamDiv: ({
    startElement,
    startOffset,
    endOffset,
    pointsProps,
  }: Omit<IMap, 'textLayers'>) => {
    addControlPoints({
      ...getRects({
        start: {
          ...getRectProps(startElement, startOffset, startElement.textContent.length),
          endOffset,
          selectedText: startElement.textContent.substring(startOffset, endOffset),
        },
      }),
      ...pointsProps,
    });
  },
  addControlPointsFromDifferentDivs: ({
    pointsProps,
    startElement,
    endElement,
    startOffset,
    endOffset,
  }: IMap) => {
    addControlPoints({
      ...getRects({
        start: getRectProps(startElement, startOffset, startElement.textContent.length),
        between: {
          startElement: startElement.nextSibling as HTMLElement,
          endElement,
        },
        end: getRectProps(endElement, 0, endOffset),
      }),
      ...pointsProps,
    });
  },
  addControlPointsFromDifferentPages: ({
    pointsProps,
    startElement,
    endElement,
    textLayers,
    startPageIndex,
    endPageIndex,
    startOffset,
    endOffset,
  }: IMap) => {
    // Add controlPoints from the first page
    addControlPoints({
      ...getRects({
        start: getRectProps(startElement, startOffset, startElement.textContent.length),
        between: {
          startElement: startElement.nextSibling as HTMLElement,
          endElement,
        },
      }),
      ...pointsProps,
    });

    // Add controlPoints between the first and last pages
    const pagesBetween = getPagesBetween(textLayers, startPageIndex, endPageIndex) || [];
    pagesBetween.forEach((ref) => addControlPoints({
        ...getRects({
          between: {
            startElement: ref.firstChild as HTMLElement,
            endElement,
          },
        }),
        ...pointsProps,
        startPageRect: ref.getBoundingClientRect(),
        pageIndex: getPageIndex(ref),
      }),
    );

    // Add controlPoints from the last page
    addControlPoints({
      ...getRects({
        between: {
          startElement: endElement.parentNode.firstChild as HTMLElement,
          endElement,
        },
        end: getRectProps(endElement, 0, endOffset),
      }),
      ...pointsProps,
      startPageRect: endElement.parentElement.getBoundingClientRect(),
      pageIndex: endPageIndex,
    });
  },
};
