import { injectable, inject } from 'inversify';
import Request from '@/Framework/api/Rpc/Request';
import CondorRpcDispatcher from '@/condor/application/DI/Rpc/HttpDispatcher';
import { RpcSuccess } from '@dealroadshow/json-rpc-dispatcher';
import CookieRepository from '@/Framework/browser/storage/CookieRepository';
import { CLOSED_HEADER_BANNERS_COOKIE_NAME } from '@/Framework/UI/Organisms/HeaderBanner/contants';

@injectable()
class BannersRepository {
  constructor(
    @inject(CondorRpcDispatcher) private readonly condorRpc: typeof CondorRpcDispatcher,
    @inject(CookieRepository) private readonly cookiesRepository: CookieRepository,
  ) {}

  getBannerByTenant = async (payload = {}) => {
    let request = new Request('condor.banner.get_banner_by_tenant', payload);
    let response = await this.condorRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  list = async (payload = {}) => {
    let request = new Request('condor.banner.get_banners', payload);
    let response = await this.condorRpc.call<RpcSuccess>(request);

    return response.getResult().payload;
  };

  positionsList = async (payload = {}) => {
    let request = new Request('condor.banner.get_positions', payload);
    let response = await this.condorRpc.call<RpcSuccess>(request);
    return response.getResult().payload;
  };

  create = async (payload = {}) => {
    const req = new Request('condor.banner.create_banner', payload);
    const response = await this.condorRpc.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  update = async (payload = {}) => {
    const req = new Request('condor.banner.update_banner', payload);
    const response = await this.condorRpc.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  delete = async (payload = {}) => {
    const req = new Request('condor.banner.delete_banner', payload);
    const response = await this.condorRpc.call<RpcSuccess>(req);

    return response.getResult().payload;
  };

  getClosedBanners = () => this.cookiesRepository.get(CLOSED_HEADER_BANNERS_COOKIE_NAME)?.split(',') || [];

  setClosedBanners = (hash) => {
    const closedBanners = this.getClosedBanners();
    if (closedBanners.length >= 5) {
      closedBanners.shift();
      closedBanners.push(hash);
    } else {
      closedBanners.push(hash);
    }
    this.cookiesRepository.set(CLOSED_HEADER_BANNERS_COOKIE_NAME, closedBanners, {
      domain: '',
      hostOnly: true,
      expires: 365,
    });
  };
}

export default BannersRepository;
