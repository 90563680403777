import React from 'react';
import cn from 'classnames';
import Spinner from '@dealroadshow/uikit/core/components/Loader/Spinner';
import {
  IRedactionListItem,
  RedactionAction,
  RedactionStructureType,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import {
  checkIsVisible,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/helpers/checkIsVisible';
import {
  getIconProps,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/RedactionMode/RedactSidebar/Redact/CategoriesList/helpers';

import styles from './categoryItem.scss';

interface IProps {
  jumpToArea: (id: string) => void,
  deleteShape: (redactionId: string, action: any, type: RedactionStructureType) => void,
  redactionItemData: IRedactionListItem,
}

const CategoryItem: React.FC<IProps> = ({
  jumpToArea,
  deleteShape,
  redactionItemData,
}) => {
  const {
    redactionId,
    count,
    id,
    label,
    action: shapeAction,
  } = redactionItemData;
  const {
    action,
    Icon,
    ...iconProps
  } = getIconProps(shapeAction);
  const isVisible = checkIsVisible(shapeAction);

  return (
    <li
      key={ id }
      className={ cn(styles.redactionsCategoryItem, { [styles.isDeleted]: shapeAction === RedactionAction.Delete }) }
      onClick={ () => !isVisible && jumpToArea(id) }
    >
      <span className={ styles.itemText }>
        { label?.length > 200 ? `${ label.slice(0, 200) }...` : label }
      </span>
      { !isVisible && count > 1 && (
        <span className={ styles.count }>{ count > 99 ? '99+' : count }</span>
      ) }
      <Icon
        { ...iconProps }
        onClick={ (e) => {
          e.stopPropagation();
          deleteShape(redactionId, action, RedactionStructureType.Redaction);
        } }
      />
      <Spinner
        overlay
        size="small"
        isVisible={ isVisible }
        className={ styles.spinner }
        overlayClassName={ cn({ [styles.spinnerOverlay]: isVisible }) }
      />
    </li>
  );
};

export default CategoryItem;
