import { useState } from 'react';
import DataroomErrorHandler from '@/dataroom/application/ErrorHandler';
import { useDIContext } from '@/Framework/DI/DIContext';
import RedactionRepository from '@/dataroom/infrastructure/repository/RedactionRepository';
import { useDataroomContext } from '@/dataroom/application/DataroomContext';
import {
  IShapesAction,
  RedactionAction,
} from '@/dataroom/ui/common/DataroomExplorer/Modals/DocumentViewer/DataroomViewer/plugins/RedactionPlugin/types';
import { IRedactionAction } from '@/dataroom/domain/vo/redaction/RedactionAction';

const getFloat = (value: number) => Number(value.toFixed(2));

const convertShapesToAction = (shapesAction: IShapesAction[]): IRedactionAction[] => (
  shapesAction.map(({
    shapes,
    action,
    structureType,
  }) => {
    const [shape] = shapes;
    return (
      action === RedactionAction.Add ? {
        structureType,
        action,
        categoryId: shape.categoryId,
        data: {
          id: shape.redactionId,
          type: shape.type,
          label: shape.label,
          entries: shapes.map(({
            controlPoints,
            id,
          }, idx) => ({
            id,
            entryNumber: idx,
            frames: controlPoints.map(({
              x,
              x1,
              y,
              y1,
              pageIndex,
            }) => ({
              topLeftX: getFloat(Math.min(x, x1)),
              topLeftY: getFloat(Math.min(y, y1)),
              bottomRightX: getFloat(Math.max(x, x1)),
              bottomRightY: getFloat(Math.max(y, y1)),
              pageIndex,
            })),
          })),
        },
      } : {
        structureType,
        action,
        categoryId: shape.categoryId,
        data: { ...shape },
      });
  })
);

export default function useSave() {
  const { container } = useDIContext();
  const { dataroom } = useDataroomContext();

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const saveAction = async (fileId: number, shapes: IShapesAction[]) => {
    setIsFetching(true);
    const payload = {
      dataroomId: dataroom.id,
      fileId,
      actions: convertShapesToAction(shapes),
    };

    try {
      const redactionRepository = container.get<RedactionRepository>(RedactionRepository);

      await redactionRepository.saveAction(payload);
    } catch (error) {
      container.get(DataroomErrorHandler)
        .handleError(error);
      throw error;
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    saveAction,
  };
}
